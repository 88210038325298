import React, { useState, useEffect, useContext } from "react";
import { createClient } from "agora-rtc-react";
import { usersConected } from "../../../context/CamsConected/CamsConected";
import VirtualBackgroundExtension from "agora-extension-virtual-background";
import Videos from "./Videos";
import { ContactData } from "../../../context/ContactData/ContactData";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import AgoraRTC from "agora-rtc-sdk-ng";
import { consoleLogFunction } from "../../../Funtions/functionLog";
import { HandleSocketContext } from "../../../context/HandleSocket/HandleSocket";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import useEncrypt from "../../../context/useEncrypt";

const config = {
  mode: "rtc",
  codec: "vp8",
};

const useClient = createClient(config);
const useDesktopClient = createClient(config);
const extension = new VirtualBackgroundExtension();
AgoraRTC.registerExtensions([extension]);

let namesUsers = [];

function VideoCall(props) {
  const { room } = useParams();
  const { setInCall } = props;

  const {
    screens,
    setScreens,
    setTracks,
    setLocalScreen,
    localScreen,
    setSpeakers,
    speakers,
    usersDisconectedImage,
  } = useContext(usersConected);
  const { base64ToUint8Array, hex2ascii } = useEncrypt();
  const { dataContact, usersName, setActionsUsers } = useContext(ContactData);
  const [popupMicrophones, setPopupMicrophones] = useState(false);
  const [microphones, setMicrophones] = useState(false);
  const { setIsSharedScreen } = useContext(HandleSocketContext);
  const {
    room: { streaming, attendee, background, settings, encryption },
  } = dataContact;

  const [loginUser, setLoginUser] = useState(false);
  const [tracks, setTracks2] = useState(false);
  const [valueChangeCamera, setValueChangeCamera] = useState(false);
  const [isCloudRecording, setIsCloudRecording] = useState(false);
  const [sID, setSID] = useState();
  const client = useClient();
  let screenClient = useDesktopClient();
  const handleFullScreen = useFullScreenHandle();
  let screen = null;
  let processor = null;

  async function getDevices() {
    // Enable log upload
    AgoraRTC.enableLogUpload();
    // Set the log output level as INFO
    AgoraRTC.setLogLevel(1);
    const tracks2 = [
      await AgoraRTC.createMicrophoneAudioTrack({ ANS: true }),
      await AgoraRTC.createCameraVideoTrack(),
    ];
    setTracks2(tracks2);
  }

  async function getProcessorInstance() {
    if (!processor && tracks[1]) {
      // Create a VirtualBackgroundProcessor instance
      processor = extension.createProcessor();

      try {
        // Initialize the extension and pass in the URL of the Wasm file
        await processor.init("../../../assets/wasms");
      } catch (e) {
        consoleLogFunction("videoCall:81", "Fail to load WASM resource!");
        return null;
      }
      // Inject the extension into the video processing pipeline in the SDK
      tracks[1].pipe(processor).pipe(tracks[1].processorDestination);
    }
    return processor;
  }

  async function setBackgroundColor() {
    if (tracks[1]) {
      let processor = await getProcessorInstance();

      try {
        processor.setOptions({ type: "color", color: background.color });
        await processor.enable();
      } finally {
        consoleLogFunction("VideoCall:99", "finalizado");
      }
    }
  }

  // Blur the user's actual background
  async function setBackgroundBlurring() {
    if (tracks[1]) {
      let processor = await getProcessorInstance();

      try {
        processor.setOptions({
          type: "blur",
          blurDegree: background.blurDegree,
        });
        await processor.enable();
      } finally {
        consoleLogFunction("videoCall:116", "cambio");
      }
    }
  }

  // Set an image as the background
  async function setBackgroundImage() {
    const imgElement = document.createElement("img");

    imgElement.onload = async () => {
      let processor = await getProcessorInstance();

      try {
        processor.setOptions({ type: "img", source: imgElement });
        await processor.enable();
      } finally {
        consoleLogFunction("videoCall:132", "cambio");
      }
    };
    imgElement.src = background.image;
  }

  async function setBackgroundVideo() {
    const videoElement = document.createElement("video");

    videoElement.onload = async () => {
      let processor = await getProcessorInstance();

      try {
        processor.setOptions({ type: "video", source: videoElement });
        await processor.enable();
      } finally {
        consoleLogFunction("videoCall:148", "cambio");
      }
    };
    videoElement.src = background.video;
  }

  async function backgroundDisable() {
    await processor.disable();
  }

  const cloudRecording = async () => {
    const body = {
      cname: streaming.channel,
      uid: String(streaming.uid),
      clientRequest: {
        resourceExpiredHour: 24,
        scene: 0,
      },
    };

    if (!isCloudRecording) {
      const suscribesDevices = speakers.map((user) => user.uid);
      suscribesDevices.push(streaming.uid);

      const bodyResquestCloud = {
        uids: suscribesDevices,
        token: streaming.token,
      };

      const requestCloud = await fetch(
        `${process.env.REACT_APP_URL_API}/rooms/${room}/recording`,
        {
          method: "POST",
          body: JSON.stringify(bodyResquestCloud),
          headers: {
            "Content-type": "application/json",
            Referer: `${process.env.REACT_APP_DOMINIO_VIRTUALES}`,
          },
        }
      );

      const result = await requestCloud.json();
      consoleLogFunction("VideoCall:195", result);
      setIsCloudRecording(true);
      setSID(result.id);

      var attendeeIO = {
        command: "RECORDING",
        name: attendee.name,
        room: streaming.channel,
        id: room,
        product: "CONTACT",
        product_id: room,
        value: true,
      };
      setActionsUsers(attendeeIO);
    }

    if (isCloudRecording) {
      const suscribesDevices = speakers.map((user) => user.uid);
      suscribesDevices.push(streaming.uid);

      const requestCloud = await fetch(
        `${process.env.REACT_APP_URL_API}/rooms/${room}/recording/${sID}/end`,
        {
          method: "POST",
          body: {},
          headers: {
            "Content-type": "application/json",
            Referer: `${process.env.REACT_APP_DOMINIO_VIRTUALES}`,
          },
        }
      );
      const resultRequestStop = await requestCloud.json();
      setIsCloudRecording(false);
      var attendeeIO = {
        command: "RECORDING",
        name: attendee.name,
        room: streaming.channel,
        id: room,
        product: "CONTACT",
        product_id: room,
        value: false,
      };
      setActionsUsers(attendeeIO);
    }
  };

  const changeCamera = async () => {
    setValueChangeCamera(!valueChangeCamera);
    const cameras = await AgoraRTC.getMicrophones();
    // tracks[1]
    //   .setDevice(cameras[!valueChangeCamera ? 1 : 0].deviceId)
    //   .then(() => {
    //     console.log("set device success");
    //   })
    //   .catch((e) => {
    //     console.log("set device error", e);
    //   });

    // tracks[1].setEncoderConfiguration(
    //   !valueChangeCamera ? "user" : "environment"
    // );
    // tracks[1].facingMode(!valueChangeCamera ? "user" : "environment")
  };

  const getMicrophone = async () => {
    const microphones = await AgoraRTC.getMicrophones();
    let listMicrophones;
    if (microphones.length > 3) {
      listMicrophones = microphones
        .filter((micro) => micro.deviceId !== "default")
        .filter((micro) => micro.deviceId !== "communications");
      setMicrophones(listMicrophones);
      setTimeout(() => {
        setPopupMicrophones(true);
      }, 1000);
    }
  };

  const changeMicrophone = (deviceId) => {
    tracks[0]
      .setDevice(deviceId)
      .then(() => {
        setPopupMicrophones(false);
      })
      .catch((e) => {
        console.error("set device error", e);
      });
  };

  const loginScreenUser = () => {
    consoleLogFunction(
      "videoCall:306 -- conectado --",
      "se conecto compartir pantalla"
    );
    screenClient
      .join(
        streaming.app,
        streaming.channel_desktop,
        streaming.token_desktop,
        streaming.uid_desktop
      )
      .then(() => {
        consoleLogFunction("videocall:206", "::conectado::");
      })
      .catch(() => {
        consoleLogFunction("videoCall:210", "::desconectado::");
      });

    screenClient.on("user-published", async (user, mediaType) => {
      await screenClient.subscribe(user, mediaType);
      if (mediaType === "video" && user.videoTrack !== undefined) {
        setScreens((prevUsers) => {
          return [...prevUsers, user];
        });
      }
      if (mediaType === "audio" && user.audioTrack !== undefined) {
        user.audioTrack.play();
      }
    });

    screenClient.on("user-left", (user) => {
      setScreens((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid);
      });
    });

    screenClient.on("user-unpublished", (user, type) => {
      if (type === "video" && user.videoTrack !== undefined) {
        setScreens((prevUsers) => {
          user.videoTrack.stop();
          consoleLogFunction(
            "videoCall:235 -- user-unpublished-screenClient",
            user.videoTrack
          );
          return prevUsers.filter((User) => User.uid !== this.user.uid);
        });
      }
    });
  };

  const sharedScreen = async () => {
    loginScreenUser();
    screen = await AgoraRTC.createScreenVideoTrack(
      {
        encoderConfig: settings.resolution_desktop,
      },
      "auto"
    );

    if (screen instanceof Array) setLocalScreen(screen[0]);
    else setLocalScreen(screen);

    setTimeout(() => {
      screenClient.publish(screen);
      if (screen instanceof Array)
        screen[0].on("track-ended", () => {
          unSharedScreen();
        });
      else
        screen.on("track-ended", () => {
          unSharedScreen();
        });
    }, 2000);
  };

  const unSharedScreen = () => {
    screenClient.removeAllListeners();
    screenClient.leave(
      function () {
        consoleLogFunction("client leaves channel");
      },
      function (err) {
        consoleLogFunction("client leave failed ", err);
      }
    );

    if (screen instanceof Array) {
      screen[0].close();
      screen[0].removeAllListeners();
    } else {
      screen.close();
      screen.removeAllListeners();
    }

    setLocalScreen(false);
    setIsSharedScreen(false);
    loginScreenUser();
  };

  const getUserName = async (uid) => {
    let name;
    namesUsers.map((user) =>
      user.uid === uid ? (name = user.name) : "undefined"
    );
    return name;
  };

  useEffect(() => {
    namesUsers = [...usersName];  
  }, [usersName]);

  useEffect(() => {
    getDevices();
    getMicrophone();
  }, []);

  useEffect(() => {
    let init = async () => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);

        setTimeout(async () => {
          const userName = await getUserName(user.uid);
          user.userName = userName;

          setSpeakers((prevUsers) => {
            if (!prevUsers.find((usr) => usr.uid === user.uid)) {
              return [...prevUsers, user];
            } else {
              const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
              return [...usrs, user];
            }
          });

          if (mediaType === "audio" && user.audioTrack !== undefined) {
            user.audioTrack.play();
          }
        }, 2000);
      });

      client.on("user-unpublished", (user, type) => {
        if (type === "audio" && user.audioTrack !== undefined) {
          user.audioTrack.stop();
        }
        setSpeakers((prevUsers) => {
          if (!prevUsers.find((usr) => usr.uid === user.uid)) {
            return [...prevUsers, user];
          } else {
            const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
            return [...usrs, user];
          }
        });
      });

      client.on("user-left", (user) => {
        setSpeakers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      if (streaming && !loginUser) {
        // if (encryption) {
        //   const encryptionSaltBase64 = base64ToUint8Array(
        //     encryption.saltBase64
        //   );
        //   const encryptionKey = hex2ascii(encryption.key);
        //   client.setEncryptionConfig(
        //     encryption.mode,
        //     encryptionKey,
        //     encryptionSaltBase64
        //   );
        // }
        await client
          .join(
            streaming.app,
            streaming.channel,
            streaming.token,
            streaming.uid
          )
          .then(() => {
            setLoginUser(true);
            consoleLogFunction("videoCall:390", "::conectado::");
          })
          .catch(() => {
            setLoginUser(false);
            consoleLogFunction("videoCall:394", "::desconectado::");
          });
      }
    };

    init();
    setTimeout(() => {
      loginScreenUser();
    }, 3000);
  }, []);

  useEffect(() => {
    if (client && tracks !== false && loginUser) {
      tracks[1].setEncoderConfiguration(settings.resolution_camera);
      tracks[1].setEncoderConfiguration("user");
      setTracks(tracks);
      const publish = async () => await client.publish([tracks[0], tracks[1]]);
      publish();
    }
  }, [client, loginUser, tracks]);

  return (
    <div className="vid">
      <FullScreen handle={handleFullScreen}>
        {popupMicrophones && (
          <div className="popup-mic">
            <button
              className="btn-close"
              onClick={() => setPopupMicrophones(false)}
            >
              X
            </button>
            <p style={{ textAlign: "center" }}>
              <FormattedMessage id="videocall.mics" />
            </p>
            {microphones.map((mic) => (
              <div
                key={mic.deviceId}
                className="items-popup-mic"
                onClick={() => changeMicrophone(mic.deviceId)}
              >
                <div className="mic-icon">
                  <img src={`${process.env.REACT_APP_CONTACT_IMG}/mic.svg`} />
                </div>{" "}
                {mic.label}
              </div>
            ))}
          </div>
        )}
        <div>
          <Videos
            users={speakers}
            localScreen={localScreen}
            tracks={tracks}
            screens={screens}
            fullScreen={handleFullScreen}
            usersDisconectedImage={usersDisconectedImage}
            dataControls={{
              isCloudRecording: isCloudRecording,
              tracks: tracks,
              setInCall: setInCall,
              sharedScreen: sharedScreen,
              unSharedScreen: unSharedScreen,
              users: speakers,
              localScreen: localScreen,
              client: client,
              desktop: screenClient,
              fullScreen: handleFullScreen,
              loginScreenUser: loginScreenUser,
              setScreens: setScreens,
              setBackgroundColor: setBackgroundColor,
              setBackgroundBlurring: setBackgroundBlurring,
              setBackgroundImage: setBackgroundImage,
              changeCamera: changeCamera,
              background: background !== undefined ? true : false,
              backgroundDisable: backgroundDisable,
              setBackgroundVideo: setBackgroundVideo,
              cloudRecording: cloudRecording,
            }}
          />
          {handleFullScreen.active && (
            <div className="logos-controls">
              <a href={`${dataContact.room.url}`} width="100">
                <img
                  src={`${dataContact.room.logo}`}
                  style={{
                    position: "relative",
                    right: "100%",
                    width: "120px",
                  }}
                ></img>
              </a>

              <a href="https://www.virtuales.io/" width="100">
                <img
                  src={`${process.env.REACT_APP_CONTACT_IMG}/logo-virtual-es.svg`}
                  style={{ position: "relative", left: "100%", width: "120px" }}
                ></img>
              </a>
            </div>
          )}
        </div>
      </FullScreen>
    </div>
  );
}

export default VideoCall;
