import { createChannel, createClient } from "agora-rtm-react";
import React, { useContext, useEffect, useState } from "react";
import { ContactData } from "../../context/ContactData/ContactData";
import { consoleLogFunction } from "../../Funtions/functionLog";

const useClient = createClient("e20fe2fdad9c40f98db4f2f99206a40a");

function GetUserForRecording() {
  const { dataContact, setSocketUser, setUsersName } = useContext(ContactData);

  const {
    room: { streaming, attendee },
  } = dataContact;

  const client = useClient();
  const [ch, setCh] = useState();

  let login = async () => {
    const uid = `recording`;
    const channel = createChannel(streaming.channel_chat);
    const testChannel = channel(client);
    setCh(testChannel);
    await client.login({
      uid,
    });
    await testChannel.join();

    client.on("ConnectionStateChanged", async (state) => {
      consoleLogFunction("ConnectionStateChanged", state);
    });

    testChannel.on("MemberLeft", () => {
      getMembers(testChannel);
      var attendeeIO = {
        command: "STATUS-USER",
        name: attendee.name,
        room: streaming.channel,
      };
      setSocketUser(attendeeIO);
    });

    testChannel.on("MemberJoined", () => {
      getMembers(testChannel);
      var attendeeIO = {
        command: "STATUS-USER",
        name: attendee.name,
        room: streaming.channel,
      };
      setSocketUser(attendeeIO);
    });

    getMembers(testChannel);
  };

  const getMembers = async (testChannel) => {
    const members = await testChannel.getMembers();
    const result = [];
    members.map((member) => {
      if (member !== "recording") {
        const user = member.split("-");
        if (!result.find((us) => us.name === atob(user[1]))) {
          result.push({
            name: atob(user[1]),
            date: user[2],
            uid: parseInt(user[0]),
          });
        }
      }
    });
    setUsersName([...result]);
  };

  useEffect(() => {
    login();
    //window.history.pushState("nextState", "nextTitle", "/room");
  }, []);

  return <div style={{ marginTop: "25px", display: "none" }}>GetUserForRecording</div>;
}

export default GetUserForRecording;
