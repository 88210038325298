import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { ContactData } from "../../../context/ContactData/ContactData";
import Button from "../../button/button";
import useRequests from "../../../Hooks/useRequests";

function ControlsUsers({ fullScreen, userId, videoMuted, audioMuted }) {
  const { room } = useParams();
  const { registerActivity } = useRequests();
  const { dataContact, setActionsUsers, usersName } = useContext(ContactData);
  const {
    room: { streaming, attendee },
  } = dataContact;

  const actionsUser = (type) => {
    var attendeeIO = {
      command: "CONTROL-USER",
      name: attendee.name,
      type: type,
      room: streaming.channel,
      id_user: userId,
      id: room,
      product: "CONTACT",
      product_id: room,
    };
    setActionsUsers(attendeeIO);
  };

  return (
    <div className="controls">
      <Button
        variant={!audioMuted ? "controls-menu" : "off"}
        onClick={() => {
          !audioMuted ? actionsUser("audio-off") : actionsUser("audio");
          registerActivity(
            attendee.name,
            room,
            audioMuted ? "MIC_ON_ATT" : "MIC_OFF_ATT",
            "ROOM",
            usersName.find((user) => user.uid === userId).name
          );
        }}
        icon={
          !audioMuted
            ? `${process.env.REACT_APP_CONTACT_IMG}/mic.svg`
            : `${process.env.REACT_APP_CONTACT_IMG}/mic-off.svg`
        }
      >
        <p className="tip-text">
          {!audioMuted ? "Desactivar Micrófono" : "Activar Micrófono"}
        </p>
      </Button>

      <Button
        variant={!videoMuted ? "controls-menu" : "off"}
        onClick={() => {
          !videoMuted ? actionsUser("video-off") : actionsUser("video");
          registerActivity(
            attendee.name,
            room,
            videoMuted ? "CAM_ON_ATT" : "CAM_OFF_ATT",
            "ROOM",
            usersName.find((user) => user.uid === userId).name
          );
        }}
        icon={
          !videoMuted
            ? `${process.env.REACT_APP_CONTACT_IMG}/cam.svg`
            : `${process.env.REACT_APP_CONTACT_IMG}/cam-off.svg`
        }
      >
        <p className="tip-text">
          {!videoMuted ? "Desactivar Cámara" : "Activar Cámara"}
        </p>
      </Button>

      <Button
        variant={!fullScreen.active ? "controls-menu" : "off-social"}
        onClick={!fullScreen.active ? fullScreen.enter : fullScreen.exit}
        icon={
          !fullScreen.active
            ? `${process.env.REACT_APP_CONTACT_IMG}/full.svg`
            : `${process.env.REACT_APP_CONTACT_IMG}/full-of.svg`
        }
      >
        <p className="tip-text">
          {!fullScreen.active ? "Full Screen" : "Pantalla normal"}
        </p>
      </Button>
      <Button
        variant="controls-menu"
        onClick={() => {
          registerActivity(
            attendee.name,
            room,
            "EXIT_ATT",
            "ROOM",
            usersName.find((user) => user.uid === userId).name
          );
          actionsUser("exit");
        }}
        icon={`${process.env.REACT_APP_CONTACT_IMG}/salir.svg`}
      >
        <p className="tip-text">Salir de la sala</p>
      </Button>
    </div>
  );
}

export default ControlsUsers;
