import { AgoraVideoPlayer } from "agora-rtc-react";
import React, { useContext } from "react";
import { usersConected } from "../../../context/CamsConected/CamsConected";
import { ContactData } from "../../../context/ContactData/ContactData";
import ControlsUsersMini from "../MainScreen/ControlsUsersMini";
import "./CameraDistribution.css";

function CameraDistribution() {
  const { speakers, screens, tracks, localScreen, usersDisconectedImage } =
    useContext(usersConected);

  const { dataContact, skin, videIsMuted } = useContext(ContactData);
  const {
    room: { attendee },
  } = dataContact;

  return (
    <div
      className={`marginTop${skin} video-grid-auditorio-${
        speakers.length + usersDisconectedImage.length
      }`}
    >
      {tracks && tracks[1] && screens.length > 0 && !videIsMuted && (
        <div className="miniCams">
          <AgoraVideoPlayer className="vid-minicams" videoTrack={tracks[1]} />
          <div className={`minicams-name${skin}`}>{attendee.name}</div>
        </div>
      )}

      {tracks && tracks[1] && localScreen && !videIsMuted && (
        <div className="miniCams">
          <AgoraVideoPlayer className="vid-minicams" videoTrack={tracks[1]} />
          <div className={`minicams-name${skin}`}>{attendee.name}</div>
        </div>
      )}

      {localScreen &&
        speakers.length > 0 &&
        speakers.map((user) => (
          <div className="miniCams" key={user.uid}>
            {!user._video_muted_ && user.videoTrack !== undefined ? (
              <AgoraVideoPlayer
                videoTrack={user.videoTrack}
                className="vid-minicams"
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_CONTACT_IMG}/fondo-sin-cam-1.jpg`}
                alt="agora-video"
                className="vid-minicams"
              />
            )}
            <ControlsUsersMini
              userId={user.uid}
              videoMuted={user._video_muted_}
              audioMuted={user._audio_muted_}
            ></ControlsUsersMini>
            <div className={`minicams-name${skin}`}>{user.userName}</div>
          </div>
        ))}

      {localScreen && videIsMuted && (
        <div className="miniCams">
          <img
            src={`${process.env.REACT_APP_CONTACT_IMG}/fondo-sin-cam-1.jpg`}
            alt="agora-video"
            style={{ width: "100%", height: "calc(100% - 30px)" }}
          />

          <div className={`minicams-name${skin}2`}>{attendee.name}</div>
        </div>
      )}

      {screens.length > 0 && videIsMuted && (
        <div className="miniCams">
          <img
            src={`${process.env.REACT_APP_CONTACT_IMG}/fondo-sin-cam-1.jpg`}
            alt="agora-video"
            style={{ width: "100%", height: "calc(100% - 30px)" }}
          />
          <div className={`minicams-name${skin}`}>{attendee.name}</div>
        </div>
      )}

      {screens.length > 0 &&
        speakers.length > 0 &&
        speakers.map((user) => (
          <div className="miniCams" key={user.uid}>
            {!user._video_muted_ && user.videoTrack !== undefined ? (
              <AgoraVideoPlayer
                videoTrack={user.videoTrack}
                className="vid-minicams"
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_CONTACT_IMG}/fondo-sin-cam-1.jpg`}
                alt="agora-video"
                className="vid-minicams"
              />
            )}
            <ControlsUsersMini
              userId={user.uid}
              videoMuted={user._video_muted_}
              audioMuted={user._audio_muted_}
            ></ControlsUsersMini>
            <div className={`minicams-name${skin}`}>{user.userName}</div>
          </div>
        ))}
    </div>
  );
}

export default CameraDistribution;
