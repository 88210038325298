import React, { createContext, useState } from "react";

export const HandleSocketContext = createContext();

function HandleSocket({ children }) {
  const [isSharedScreen, setIsSharedScreen] = useState(false);
  return (
    <HandleSocketContext.Provider value={{ isSharedScreen, setIsSharedScreen }}>
      {children}
    </HandleSocketContext.Provider>
  );
}

export default HandleSocket;
