import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import HeaderRecording from "../components/header/HeaderRecording";
import Loading from "../components/Loading/Loading";
import MainRecording from "../components/main/MainRecording";
import CamsConected from "../context/CamsConected/CamsConected";
import { ContactData } from "../context/ContactData/ContactData";
import useRequests from "../Hooks/useRequests";

function ViewRecording() {
  const { setDataContact, skin, setSkin, dataContact } =
    useContext(ContactData);
  const { getWithToken } = useRequests();
  const { room, style } = useParams();

  useEffect(() => {
    const controller = new AbortController();

    getdataContact();

    if (style && style === "white") {
      document.body.style.backgroundColor = "var(--bg-body)";
      setSkin("-white");
    }

    return () => {
      controller.abort();
    };
  }, []);

  const getdataContact = async () => {
    const data = await getWithToken(
      `${process.env.REACT_APP_URL_API}/rooms/${room}`
    );
    if (data.response.status === 200) setDataContact(data.result);
  };

  return (
    <CamsConected>
      <div className={`App${skin}`} style={{ overflow: "hidden" }}>
        <div className="container" style={{ margin: "auto" }}>
          {dataContact ? <MainRecording /> : <Loading></Loading>}
        </div>
        <Footer />
      </div>
    </CamsConected>
  );
}

export default ViewRecording;
