import React, { useContext } from 'react'
import { ContactData } from '../../../context/ContactData/ContactData';

function InfoContactHome() {
    const { skin } = useContext(ContactData);
    return (
        <div className="flex-box">
          <div className="box-info-firt">
            <h1 className={`titulo-evento${skin}`} style={{padding: "0"}}>
              {"Acceso a Centro de Contacto"} <br />
              <span className="weight-normal">
                {"Bienvenido al sistema de centro de contacto"}
              </span>
            </h1>
          </div>
          <a target="_blank" href={"https://www.virtuales.io"} rel="noopener noreferrer">
            <div className={`logo-cabecera${skin}`}>
              <img style={{width: "90%", height: "65%", display: "block", margin: "auto", marginTop: "20px"}} alt="logo cabecera" src={`${process.env.REACT_APP_CONTACT_IMG}/virtual-es-09.png`}  />
            </div>
          </a>
        </div>
      );
}

export default InfoContactHome