import React from 'react'
import "./Popup.css"

function Popup({children}) {
  return (
    <div className='light-box'>
        <div className='popup'>{children}</div>
    </div>
  )
}

export default Popup