import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ContactData } from "../../../context/ContactData/ContactData";

import "./Marquee.css";

function BannerNext({ marquee }) {
  const { skin, socketUser } = useContext(ContactData);
  const [statusMarquee, setStatusMarquee] = useState();
  useEffect(() => {
    if (socketUser && socketUser.command === "RECORDING") {
      setStatusMarquee(socketUser.value);
    }
  }, [socketUser]);

  return (
    <div className={`marquee marquee${skin}`}>
      <marquee>
        {marquee.includes("<a href") ? (
          <div>
            {marquee.split("<a href=")[0]}{" "}
            <a
              style={{ color: "#46b2ce" }}
              href={marquee
                .split("href=")[1]
                .split(">")[0]
                .replace(`"`, "")
                .replace(`"`, "")}
              target="_blank"
            >
              {marquee.split(">")[1].split("<")[0]}
            </a>
            <span style={{ color: "red" }}>
              {statusMarquee && (
                <FormattedMessage id={"meeting.recording"}></FormattedMessage>
              )}
            </span>
          </div>
        ) : (
          <div>
            {marquee}{" "}
            <span style={{ color: "red" }}>
              {statusMarquee && (
                <FormattedMessage id={"meeting.recording"}></FormattedMessage>
              )}
            </span>{" "}
          </div>
        )}
      </marquee>
    </div>
  );
}

export default BannerNext;
