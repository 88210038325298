import React, { useContext, useEffect, useRef, useState } from "react";
import { ContactData } from "../../context/ContactData/ContactData";
import { FormattedMessage } from "react-intl";

function TimeControl({ controlTime, setRemoteTimeControl }) {
  const { skin } = useContext(ContactData);
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  const [statusClock, setStatusClock] = useState(true);
  const [date, setDate] = useState(false);
  let interval;

  const startTimer = () => {
    const countDownDate = new Date(date).getTime();
    let now = new Date().getTime();
   
    interval = setInterval(() => {
      const distance = countDownDate - now;
  
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        setStatusClock(false);
        setRemoteTimeControl({ minutes: 0, seconds: 0 });
        
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
      now += 1000;
    }, 1000);
  };

  useEffect(() => {
    const dateEnd = new Date(
      new Date(
        new Date().setSeconds(new Date().getSeconds() + controlTime.seconds)
      ).setMinutes(new Date().getMinutes() + controlTime.minutes)
    );
    setDate(dateEnd);
    return () => {
      setDate(false);
    };
  }, [controlTime]);

  useEffect(() => {
    if (date) startTimer();
    return () => {
      clearInterval(interval);
    };
  }, [date]);

  useEffect(() => {
    clearInterval(interval);
    return () => {
      clearInterval(interval);
    };
  }, [statusClock]);
  return (
    <div style={{ marginTop: "15px" }}>
      <div className={`box-countdown${skin}`}>
        <div className={`Countdown-item${skin}`}>
          <h1>{timerMinutes}</h1>
          <h3>
            <FormattedMessage id={"count-down.minutes"}></FormattedMessage>
          </h3>
        </div>
        <div className={`Countdown-item${skin}`}>
          <h1>:</h1>
        </div>
        <div className={`Countdown-item${skin}`}>
          <h1>{timerSeconds}</h1>
          <h3>
            <FormattedMessage id={"count-down.seconds"}></FormattedMessage>
          </h3>
        </div>
      </div>
    </div>
  );
}

export default TimeControl;
