import React, { useContext, useEffect, useRef } from "react";
import Main from "../components/main/main";
import Footer from "../components/Footer/Footer";
import CamsConected from "../context/CamsConected/CamsConected";
import { ContactData } from "../context/ContactData/ContactData";
import Loading from "../components/Loading/Loading";
import HeaderLink from "../components/header/HeaderLink";
import { useState } from "react";
import Popup from "../components/Popups/Popup";
import CountDownControlAccess from "../components/CountDown/CountDownControlAccess";
import { useParams } from "react-router-dom";
import useRequests from "../Hooks/useRequests";
import { FormattedMessage, useIntl } from "react-intl";

function Auditorium() {
  const {
    dataContact,
    setDataContact,
    skin,
    setSkin,
    socketUser,
    countDownAccess,
  } = useContext(ContactData);
  const intl = useIntl();
  const { getWithToken } = useRequests();
  const { room, style } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const inputName = useRef();

  useEffect(() => {
    const controller = new AbortController();

    getdataContact();

    if (style && style === "white") {
      document.body.style.backgroundColor = "var(--bg-body)";
      setSkin("-white");
    }

    return () => {
      controller.abort();
    };
  }, []);

  const getdataContact = async () => {
    const data = await getWithToken(
      `${process.env.REACT_APP_URL_API}/rooms/${room}`
    );

    if (data.response.status === 200) {
      setDataContact(data.result);
    } else if (data.response.status === 404) {
      window.location = "/room";
    }
  };

  const handleClickAddName = () => {
    if (inputName.current.value !== "") {
      setDataContact({
        room: {
          ...dataContact.room,
          attendee: { name: inputName.current.value },
        },
      });
    }
  };

  useEffect(() => {
    if (
      socketUser &&
      socketUser.type === "end-meet" &&
      socketUser.command === "END"
    ) {
      setIsOpen(true);
      setTimeout(() => {
        window.location.href = dataContact.room.url;
      }, 3000);
    }
  }, [socketUser]);
  
  try {
    return (
      <CamsConected>
        <div className={`App${skin}`}>
          <HeaderLink
            userName={
              dataContact && dataContact.room.attendee !== null
                ? dataContact.room.attendee.name
                : " "
            }
          />
          <div className="container" style={{ margin: "auto" }}>
            {isOpen && (
              <Popup>
                <div className="popup-content">
                  <h1>
                    <FormattedMessage id="audit.the-date-over" />
                  </h1>
                </div>
              </Popup>
            )}
            {countDownAccess &&
              dataContact &&
              dataContact.room.visible &&
              dataContact.room.rol !== "MANAGER" && <CountDownControlAccess />}
            {countDownAccess && dataContact && !dataContact.room.visible && (
              <h1 className="tittle-expired-room">
                <FormattedMessage id="audit.expired-room-not-exist" />
              </h1>
            )}
            {dataContact && dataContact.room.rol === "MANAGER" && <Main />}
            {!dataContact ? (
              <Loading />
            ) : !countDownAccess &&
              dataContact.room.attendee !== null &&
              dataContact.room.date_end_utc > dataContact.room.date &&
              dataContact.room.visible ? (
              <Main />
            ) : (
              <>
                {!countDownAccess &&
                  dataContact.room.attendee === null &&
                  dataContact.room.date_end_utc > dataContact.room.date &&
                  dataContact.room.visible && (
                    <div className="box-input-name">
                      <h2>
                        <FormattedMessage id="audit.enter-name" />
                      </h2>
                      <input
                        type="text"
                        name="name"
                        ref={inputName}
                        placeholder={intl.formatMessage({ id: "input.name" })}
                      ></input>
                      <button onClick={handleClickAddName}>
                        <FormattedMessage id="audit.get-into" />
                      </button>
                    </div>
                  )}
                {!countDownAccess &&
                  dataContact.room.date_end_utc < dataContact.room.date &&
                  dataContact.room.visible && (
                    <h1 className="tittle-expired-room">
                      <FormattedMessage id="audit.expired-room" />
                    </h1>
                  )}
                {!countDownAccess && !dataContact.room.visible && (
                  <h1 className="tittle-expired-room">
                    <FormattedMessage id="audit.expired-room-not-exist" />
                  </h1>
                )}
              </>
            )}
          </div>
          <Footer />
        </div>
      </CamsConected>
    );
  } catch (error) {
    console.error(error);
  }
}

export function getActualHour() {
  var d = new Date();
  var s = "" + d.getUTCFullYear();

  if (d.getUTCMonth() + 1 < 10) {
    s += "0" + (d.getUTCMonth() + 1);
  } else {
    s += "" + (d.getUTCMonth() + 1);
  }
  if (d.getUTCDate() < 10) {
    s += "0" + d.getUTCDate();
  } else {
    s += "" + d.getUTCDate();
  }
  if (d.getUTCHours() < 10) {
    s += "0" + d.getUTCHours();
  } else {
    s += "" + d.getUTCHours();
  }
  if (d.getUTCMinutes() < 10) {
    s += "0" + d.getUTCMinutes();
  } else {
    s += "" + d.getUTCMinutes();
  }
  if (d.getUTCSeconds() < 10) {
    s += "0" + d.getUTCSeconds();
  } else {
    s += "" + d.getUTCSeconds();
  }
  var actualHour = parseInt(s);
  return actualHour;
}

export default Auditorium;
