import React, { useContext } from "react";
import { ContactData } from "../../context/ContactData/ContactData";
import { langContext } from "../../context/lang/langContext";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

function HeaderDesktopLink({ userName }) {
  const { establecerLenguaje, locale } = useContext(langContext);
  const { skin } = useContext(ContactData);

  return (
    <>
      <header className={`header${skin}`}>
        <div className="container-nav flex">
          <img
            src={
              skin === "-white"
                ? `${process.env.REACT_APP_CONTACT_IMG}/virtual-es-09.png`
                : `${process.env.REACT_APP_CONTACT_IMG}/logo-virtual-es.svg`
            }
            className="logo-header"
            alt="logo-virtuales"
          />
          <ul className={`nav nav${skin}`}>
            <li>
              <FormattedMessage
                id="header.welcome"
                defaultMessage="Bienvenido(a) "
              />
              {userName && userName}
            </li>
            <li className="">
              <Link to="/">
                <img
                  className="imgfaq"
                  src={`${process.env.REACT_APP_CONTACT_IMG}/faq.svg`}
                  alt="faq"
                  width="55"
                  height="55"
                />
              </Link>
            </li>
            <li>
              <div>
                <img
                  className="imgfaq"
                  src={`${process.env.REACT_APP_CONTACT_IMG}/reino-unido.png`}
                  alt="faq"
                  width="30"
                  height="30"
                  onClick={() => establecerLenguaje("en-US")}
                  style={locale === "en-US" ? { border: "2px solid",backgroundColor: "white" } : {}}
                />
                <img
                  className="imgfaq"
                  src={`${process.env.REACT_APP_CONTACT_IMG}/colombia.png`}
                  alt="faq"
                  width="30"
                  height="30"
                  onClick={() => establecerLenguaje("es-MX")}
                  style={locale === "es-MX" ? { border: "2px solid", backgroundColor: "white" } : {}}
                />
              </div>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
}

export default HeaderDesktopLink;
