import { AgoraVideoPlayer } from "agora-rtc-react";
import React, { useContext, useState } from "react";
import { ContactData } from "../../../context/ContactData/ContactData";

function VideoRecording(props) {
  const { users, localScreen, tracks, screens, usersDisconectedImage } = props;
  const { dataContact, videIsMuted } = useContext(ContactData);

  const {
    room: { attendee, rol },
  } = dataContact;
  const trackValue = !tracks ? -1 : 0;

  return (
    <div className="screen-recording">
      <div
        id={"videos-recording"}
        className={
          localScreen || screens.length > 0 || (!tracks && users.length === 1)
            ? "video-grid-auditorio-sreen"
            : `video-grid-auditorio-${
                users.length + usersDisconectedImage.length + trackValue
              }`
        }
      >
        {screens.length > 0 && (
          <div style={{ width: "100%", height: "calc(100% - 40px)" }}>
            <AgoraVideoPlayer
              style={{ height: "calc(100%)", width: "100%" }}
              videoTrack={screens[0].videoTrack}
            />
          </div>
        )}

        {!localScreen && screens.length === 0 && tracks && (
          <div
            style={
              users.length === 0 ? { width: "100%", height: "calc(100%)" } : {}
            }
            className={"a-0"}
          >
            {videIsMuted ? (
              <div style={{ width: "100%", height: "calc(100%)" }}>
                <div
                  style={{
                    width: "100%",
                    height: "calc(100% - 40px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_CONTACT_IMG}/fondo-sin-cam-1.jpg`}
                    className="image-distribution"
                  />
                </div>
                <div className="name-user">{attendee.name}</div>
              </div>
            ) : (
              tracks !== false && (
                <div style={{ width: "100%", height: "100%" }}>
                  <AgoraVideoPlayer
                    className="agora-video"
                    videoTrack={tracks[1]}
                  />
                  <div className="name-user">{attendee.name}</div>
                </div>
              )
            )}
          </div>
        )}

        {users.length > 0 &&
          !localScreen &&
          screens.length === 0 &&
          users.map((user, index) => (
            <div
              style={{ width: "100%", height: "100%" }}
              className={`a-${index + 1}`}
              key={user.uid}
            >
              {!user._video_muted_ && user.videoTrack !== undefined ? (
                <AgoraVideoPlayer
                  className="agora-video-users"
                  videoTrack={user.videoTrack}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "calc(100% - 40px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_CONTACT_IMG}/fondo-sin-cam-1.jpg`}
                    className="image-distribution"
                  />
                </div>
              )}

              <div className="name-user">{user.userName}</div>
            </div>
          ))}

        {!tracks &&
          usersDisconectedImage.length === 0 &&
          users.length === 0 &&
          !localScreen &&
          screens.length === 0 && (
            <img
              src={`${process.env.REACT_APP_CONTACT_IMG}/fondo.jpeg`}
              alt="main screen"
              className="screenVideo-recording"
            />
          )}

        {localScreen && (
          <div style={{ width: "100%", height: "calc(100% - 40px)" }}>
            <AgoraVideoPlayer
              style={{ height: "100%", width: "100%" }}
              className="vid"
              videoTrack={localScreen}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default VideoRecording;
