import React from "react";
import HandleSocket from "../../context/HandleSocket/HandleSocket";
import CameraDistributionRecordin from "./CameraDistribution/CameraDistributionRecordin";
import VideoCallRecording from "./MainScreen/VideoCallRecording";
import InfoContact from "./BannerLogo/InfoContact";
import GetUserForRecording from "../ChatComponent/GetUserForRecording";

function MainRecording() {
  return (
    <>
      <HandleSocket>
        <InfoContact />
        <section className="auditorium" style={{ marginTop: "10px" }}>
          <div className="videoWrapper-recording">
            <section className="videoMain">
              <div>
                <div className={"display-video-recording"}>
                  <VideoCallRecording />
                </div>
              </div>
            </section>
          </div>
          <div className="chat-recording" style={{ marginTop: "-25px" }}>
            <GetUserForRecording />
            <CameraDistributionRecordin />
          </div>
        </section>
      </HandleSocket>
    </>
  );
}

export default MainRecording;
