import React, { useContext } from "react";

import VideoInfo from "./MainScreen/video";

import { ContactData } from "../../context/ContactData/ContactData";
import BannerNext from "./bannerNextEvents/marquee";

function VideoInfoWrapper() {

  const { dataContact } = useContext(ContactData);
  
  return (
    <div className="videoWrapper">
      <VideoInfo>
        {dataContact.room.marquee && <BannerNext marquee={dataContact.room.marquee }></BannerNext>}
      </VideoInfo>
    </div>
  );
}

export default VideoInfoWrapper;
