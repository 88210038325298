import React, { useContext, useEffect, useState } from "react";
import Button from "../../button/button";
import { ContactData } from "../../../context/ContactData/ContactData";
import useRequests from "../../../Hooks/useRequests";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

function Controls({
  dataControls,
  variant,
  screens,
  trackState,
  setTrackState,
}) {
  const { dataContact, setVideIsMuted, socketUser, setActionsUsers } =
    useContext(ContactData);
  const { room } = useParams();
  const { registerActivity } = useRequests();
  const [isOpen, setIsOpen] = useState(false);

  const {
    room: { streaming, attendee, rol, plan },
  } = dataContact;

  const muteAll = () => {
    var attendeeSend = {
      type: "MUTE-AUDIO-ALL",
      name: attendee.name,
      room: room,
      command: "CONTROL-USER",
      id: streaming.uid,
    };
    setActionsUsers(attendeeSend);
  };

  const mute = (type) => {
    if (type === "audio") {
      dataControls.tracks[0].setEnabled(trackState.audio ? false : true);
      setTrackState((ps) => {
        return {
          ...ps,
          audio: trackState.audio ? false : true,
        };
      });
    } else if (type === "video") {
      dataControls.tracks[1].setEnabled(trackState.video ? false : true);
      setTrackState((ps) => {
        return {
          ...ps,
          video: trackState.video ? false : true,
        };
      });
      setVideIsMuted(trackState.video);
    }
  };

  const exitToOrigin = async () => {
    window.location.href = dataContact.room.url;
  };

  const leaveChannel = async () => {
    await dataControls.client.leave();
    dataControls.client.removeAllListeners();
    dataControls.desktop.leave();
    dataControls.desktop.removeAllListeners();
    // we close the tracks to perform cleanup
    registerActivity(attendee.name, room, "EXIT_ROOM", "ROOM");
    dataControls.tracks[0].close();
    dataControls.tracks[1].close();
    dataControls.setScreens([]);
    dataControls.setInCall(false);
  };

  const endSession = () => {
    var attendeeIO = {
      command: "END-SESSION",
      name: attendee.name,
      value: true,
    };
    setActionsUsers(attendeeIO);
    registerActivity(attendee.name, room, "END", "ROOM");
    setTimeout(() => {
      exitToOrigin();
    }, 2000);
  };

  useEffect(() => {
    if (socketUser && socketUser !== false) {
      if (socketUser.command === "CONTROL-USER") {
        if (socketUser.id_user === streaming.uid && socketUser.type === "exit")
          exitToOrigin();
        if (
          socketUser.id_user === streaming.uid &&
          socketUser.type === "audio-off" &&
          trackState.audio
        )
          mute("audio");

        if (
          socketUser.id_user !== streaming.uid &&
          socketUser.type === "MUTE-AUDIO-ALL" &&
          trackState.audio
        )
          mute("audio");

        if (
          socketUser.id_user === streaming.uid &&
          socketUser.type === "video-off" &&
          trackState.video
        )
          mute("video");
      }

      if (
        attendee.name !== socketUser.name &&
        socketUser.command === "DESKTOP-SHARING"
      ) {
        dataControls.loginScreenUser();
      } else if (socketUser.command === "END-DESKTOP-SHARING") {
        dataControls.setScreens([]);
        dataControls.desktop.leave();
        dataControls.desktop.removeAllListeners();
      }
    }
  }, [socketUser]);

  return (
    rol && (
      <div className={variant}>
        <Button
          variant={trackState.audio ? "controls-menu" : "off"}
          onClick={() => {
            mute("audio");
            registerActivity(
              attendee.name,
              room,
              trackState.audio ? "MIC_OFF" : "MIC_ON",
              "ROOM"
            );
          }}
          icon={
            trackState.audio
              ? `${process.env.REACT_APP_CONTACT_IMG}/mic.svg`
              : `${process.env.REACT_APP_CONTACT_IMG}/mic-off.svg`
          }
        >
          <p className="tip-text">
            {trackState.audio ? "Desactivar Micrófono" : "Activar Micrófono"}
          </p>
        </Button>
        {rol && rol === "MANAGER" && (
          <Button
            variant="controls-menu"
            onClick={() => {
              muteAll();
              registerActivity(attendee.name, room, "MIC_OFF_ALL", "ROOM");
            }}
            icon={`${process.env.REACT_APP_CONTACT_IMG}/mics-off.svg`}
          >
            <p className="tip-text">
              <FormattedMessage id="controls.muteAllMics" />
            </p>
          </Button>
        )}
        <Button
          variant={trackState.video ? "controls-menu" : "off"}
          onClick={() => {
            mute("video");
            registerActivity(
              attendee.name,
              room,
              trackState.video ? "CAM_OFF" : "CAM_ON",
              "ROOM"
            );
          }}
          icon={
            trackState.video
              ? `${process.env.REACT_APP_CONTACT_IMG}/cam.svg`
              : `${process.env.REACT_APP_CONTACT_IMG}/cam-off.svg`
          }
        >
          <p className="tip-text">
            {trackState.video ? "Desactivar Cámara" : "Activar Cámara"}
          </p>
        </Button>

        {dataControls.background && (
          <div
            className={"button--controls-menu mobile-none"}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen && (
              <p className="tip-text2">
                <Button
                  variant={"controls-menu2"}
                  onClick={() => {
                    dataControls.setBackgroundImage();
                    registerActivity(attendee.name, room, "BGROUND_ON", "ROOM");
                  }}
                  icon={`${process.env.REACT_APP_CONTACT_IMG}/backgrounds-image.svg`}
                >
                  <p className="tip-text3">Background image</p>
                </Button>
                <Button
                  variant={"controls-menu2"}
                  onClick={() => {
                    dataControls.setBackgroundBlurring();
                    registerActivity(attendee.name, room, "BGROUND_ON", "ROOM");
                  }}
                  icon={`${process.env.REACT_APP_CONTACT_IMG}/backgrounds-blur.svg`}
                >
                  <p className="tip-text3">Background Blur</p>
                </Button>
                <Button
                  variant={"controls-menu2"}
                  onClick={() => {
                    dataControls.setBackgroundColor();
                    registerActivity(attendee.name, room, "BGROUND_ON", "ROOM");
                  }}
                  icon={`${process.env.REACT_APP_CONTACT_IMG}/backgrounds-color.svg`}
                >
                  <p className="tip-text3">Background Color</p>
                </Button>
                {/* <Button
                variant={"controls-menu"}
                onClick={() => setBackgroundVideo()}
              >
                video
              </Button> */}
                <Button
                  variant={"controls-menu2"}
                  onClick={() => {
                    dataControls.backgroundDisable();
                    registerActivity(
                      attendee.name,
                      room,
                      "BGROUND_OFF",
                      "ROOM"
                    );
                  }}
                  icon={`${process.env.REACT_APP_CONTACT_IMG}/backgrounds-disabled.svg`}
                >
                  <p className="tip-text3">Background Disable</p>
                </Button>
              </p>
            )}
            <p className="tip-text" style={{ fontWeight: "700" }}>
              Background
            </p>
            <img
              src={`${process.env.REACT_APP_CONTACT_IMG}/backgrounds-off.svg`}
              width="30"
            ></img>
          </div>
        )}
        {screens.length === 0 && (
          <Button
            variant={
              dataControls.localScreen
                ? "off-social"
                : "controls-menu mobile-none"
            }
            onClick={
              dataControls.localScreen
                ? () => {
                    dataControls.unSharedScreen();
                    registerActivity(attendee.name, room, "SCREEN_OFF", "ROOM");
                  }
                : () => {
                    dataControls.sharedScreen();
                    registerActivity(attendee.name, room, "SCREEN_ON", "ROOM");
                  }
            }
            icon={
              !dataControls.localScreen
                ? `${process.env.REACT_APP_CONTACT_IMG}/pantalla.svg`
                : `${process.env.REACT_APP_CONTACT_IMG}/pantalla-of.svg`
            }
          >
            <p className="tip-text">
              {dataControls.localScreen
                ? "Dejar de Compartir pantalla"
                : "Compartir Pantalla"}
            </p>
          </Button>
        )}
        <Button
          variant={
            !dataControls.fullScreen.active ? "controls-menu" : "off-social"
          }
          onClick={
            !dataControls.fullScreen.active
              ? () => {
                  dataControls.fullScreen.enter();
                  registerActivity(attendee.name, room, "FSCREEN_ON", "ROOM");
                }
              : () => {
                  dataControls.fullScreen.exit();
                  registerActivity(attendee.name, room, "FSCREEN_OFF", "ROOM");
                }
          }
          icon={
            !dataControls.fullScreen.active
              ? `${process.env.REACT_APP_CONTACT_IMG}/full.svg`
              : `${process.env.REACT_APP_CONTACT_IMG}/full-of.svg`
          }
        >
          <p className="tip-text">
            {!dataControls.fullScreen.active
              ? "Full Screen"
              : "Pantalla normal"}
          </p>
        </Button>
        <Button
          variant="controls-menu"
          onClick={() => leaveChannel()}
          icon={`${process.env.REACT_APP_CONTACT_IMG}/salir.svg`}
        >
          <p className="tip-text">Salir de la sala</p>
        </Button>
        {rol && rol === "MANAGER" && (
          <Button
            variant="controls-menu"
            onClick={() => endSession()}
            icon={`${process.env.REACT_APP_CONTACT_IMG}/terminar.svg`}
          >
            <p className="tip-text">Terminar reunion</p>
          </Button>
        )}
        {rol && rol === "MANAGER" && plan.recording.enabled && (
          <Button
            variant={
              !dataControls.isCloudRecording
                ? "off-social mobile-none"
                : "off"
            }
            onClick={() => {
              dataControls.cloudRecording();
              registerActivity(
                attendee.name,
                room,
                !dataControls.isCloudRecording ? "REC_ON" : "REC_OFF",
                "ROOM"
              );
            }}
            icon={
              dataControls.isCloudRecording
                ? `${process.env.REACT_APP_CONTACT_IMG}/record-off.svg`
                : `${process.env.REACT_APP_CONTACT_IMG}/record-on.svg`
            }
          >
            {!dataControls.isCloudRecording ? (
              <p className="tip-text">Grabar</p>
            ) : (
              <p className="tip-text">Dejar de grabar</p>
            )}
          </Button>
        )}
        <Button
          variant="controls-menu mobile mobile-none"
          onClick={() => dataControls.changeCamera()}
        >
          <p className="tip-text">cambiar camara</p>
        </Button>
      </div>
    )
  );
}

export default Controls;
