import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import Appointment from "../components/Appointment/Appointment";
import Footer from "../components/Footer/Footer";
import HeaderAppointment from "../components/header/HeaderAppointment";
import Popup from "../components/Popups/Popup";
import { ContactData } from "../context/ContactData/ContactData";
import { langContext } from "../context/lang/langContext";
import useRequests from "../Hooks/useRequests";

function ViewAppointment() {
  const intl = useIntl();
  const { locale } = useContext(langContext);
  const { getWithToken, postWithToken } = useRequests();
  const { appointment } = useParams();
  const [data, setData] = useState(false);
  const [data2, setData2] = useState(false);
  const [dateSelected, setDateSelected] = useState(false);
  const [dates, setDates] = useState(false);
  const [datesSidebar, setDatesSidebar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [isOpenSidebarBody, setIsOpenSidebarBody] = useState(false);
  const [hourSelected, setHourSelected] = useState(false);
  const { setDataContact, dataContact } = useContext(ContactData);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [contentAlert, setContentAlert] = useState(false);
  const [formData, setFormData] = useState({
    date: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    comments: "",
  });

  const getData = async () => {
    const re = await getWithToken(
      `${process.env.REACT_APP_URL_API}/appointment/${appointment}`
    );
    const r = Object.assign({}, re.result);
    setData(r.calendar.slice());
    setData2(re.result.calendar);
    setDataContact(re.result);
  };

  const sendData = async (e) => {
    e.preventDefault();
    formData.date = hourSelected.type_date;

    const result = await postWithToken(
      `${process.env.REACT_APP_URL_API}/appointment/${appointment}`, formData
    );

    if (result.response.status === 200) {
      setContentAlert({
        alert: "Su cita ha sido agendada",
        type: "success",
      });
      setIsOpenAlert(true);
    }

    if (result.status === 400) {
      setContentAlert({
        alert: "Su cita no puede ser agendada, por favor seleccione otra fecha",
        type: "danger",
      });
      setIsOpenAlert(true);
      setTimeout(() => {
        setIsOpenAlert(false);
      }, 2000);
    }
  };

  const statusSidebar = (e) => {
    if (isOpen) {
      setIsOpenSidebar(false);
      setTimeout(() => {
        setIsOpenSidebarBody(false);
        setIsOpen(false);
      }, 500);
    }
    if (!isOpen) {
      setDateSelected(e.event._def.extendedProps.dui);
      const arrayDatesSelected = data2.filter(
        (date) => date.date.split("T")[0] === e.event._def.extendedProps.dui
      );
      setDatesSidebar(arrayDatesSelected);
      setIsOpen(true);
      setTimeout(() => {
        setIsOpenSidebar(true);
        window.location.href = `/appointment/${appointment}#sidebar-head`;
      }, 500);
    }
  };

  const handleOnChange = (e) => {
    setFormData((form) => {
      return { ...form, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data) {
      const arrayDates = [];

      data.forEach((date) => {
        if (
          arrayDates.find(
            (search) => search.date.split("T")[0] === date.date.split("T")[0]
          ) === undefined
        ) {
          const copyDate = { date: "", dui: "", title: "" };
          copyDate.dui = date.date.split("T")[0];
          copyDate.date = date.date.split("T")[0];
          const amount = data.filter(
            (search) => search.date.split("T")[0] === date.date.split("T")[0]
          );
          copyDate.title =
            amount.length +
            " " +
            intl.formatMessage({ id: "schedule.Avaible" });
          arrayDates.push(copyDate);
        }
      });
      setDates(arrayDates);
    }
  }, [data, locale]);

  return (
    <div>
      <HeaderAppointment />
      {isOpenAlert && (
        <Popup>
          <div className="content-popup">
            <h2>
              {contentAlert.alert} <br></br>
              <FormattedMessage id={"register.messagePopup"} />
            </h2>
            <button
              className="button--early"
              onClick={() => {
                window.location.href = dataContact.url;
              }}
            >
              <FormattedMessage id={"popup.btnExit"} />
            </button>
          </div>
        </Popup>
      )}
      {isOpen && (
        <div className="light-sidebar">
          <div className={!isOpenSidebar ? "sidebar" : "sidebar open"}>
            <div className="sidebar-head" id="sidebar-head">
              <button
                className="button-sidebar"
                onClick={() => statusSidebar()}
              >
                {">"}
              </button>
              <h2>{dateSelected}</h2>
            </div>
            <div>
              <div className="logo-sidebar">
                {" "}
                <img src={`${dataContact.logo}`} alt="logo"></img>
              </div>
              <h4 className="title-disponibility">
                <FormattedMessage id={"register.Disponibility"} />{" "}
                {dataContact.title} {dataContact.subtitle}{" "}
              </h4>

              {!isOpenSidebarBody ? (
                <ul className="sidebal-list">
                  {datesSidebar &&
                    datesSidebar.map((date, i) => {
                      const hour = new Date(date.date).toLocaleString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                      });

                      return (
                        <li
                          key={i}
                          className={"sidebar-items"}
                          onClick={() => {
                            setHourSelected({
                              human_date: new Date(date.date).toLocaleString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              ),
                              type_date: date.date,
                            });
                            setIsOpenSidebarBody(true);
                          }}
                        >
                          {hour}
                        </li>
                      );
                    })}
                </ul>
              ) : (
                <div className="body-sidebar">
                  <h3>
                    <FormattedMessage id={"register.Title"} />
                  </h3>
                  <p>
                    <FormattedMessage id={"register.TheDay"} />{" "}
                    {hourSelected.human_date.split(",")[0]}{" "}
                    <FormattedMessage id={"register.To"} />{" "}
                    {hourSelected.human_date.split(",")[1]}
                  </p>
                  <form
                    onChange={(e) => handleOnChange(e)}
                    onSubmit={(e) => sendData(e)}
                  >
                    <label>
                      <FormattedMessage id={"register.name"} />
                    </label>
                    <input type={"text"} name={"first_name"} required></input>
                    <label>
                      <FormattedMessage id={"register.lastname"} />
                    </label>
                    <input type={"text"} name={"last_name"} required></input>
                    <label>
                      <FormattedMessage id={"register.email"} />
                    </label>
                    <input type={"email"} name={"email"} required></input>
                    <label>
                      <FormattedMessage id={"register-new.Phone"} />
                    </label>
                    <input type={"number"} name={"phone"}></input>
                    <label>
                      <FormattedMessage id={"register.comment"} />
                    </label>
                    <textarea name="comments" required></textarea>
                    <div className="sidebar-form-buttoms">
                      <button
                        onClick={() => {
                          setIsOpenSidebarBody(false);
                        }}
                      >
                        <FormattedMessage id={"register.btnCancel"} />
                      </button>
                      <button>
                        <FormattedMessage id={"register.btnConfirm"} />
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className="container calendar"
        style={{
          margin: "25px auto",
          padding: "50px",
          backgroundColor: "#fff",
          borderRadius: "10px",
        }}
      >
        <Appointment statusSidebar={statusSidebar} dates={dates}></Appointment>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default ViewAppointment;
