import React from "react";
import Footer from "../components/Footer/Footer";
import ContactDetail from "../components/ContactDetail/ContactDetail";
import Header from "../components/header/header";

function AccessDenied() {
  return (
    <div>
      <Header />
      <ContactDetail />
      <Footer />
    </div>
  );
}

export default AccessDenied;
