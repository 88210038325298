import Hamburger from "hamburger-react";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { ContactData } from "../../context/ContactData/ContactData";

function HeaderMovileAppointment({ logo, title, subTitle }) {
  const { skin } = useContext(ContactData);
  const [isOpen, setOpen] = useState(false);

  const isToggled = (toggled) => {
    const navbar = document.getElementById("nav");
    if (toggled) {
      navbar.className = "nav";
    } else {
      navbar.className = "nav hidden";
    }
  };

  return (
    <>
      <header className={`header${skin}`}>
        <div className="wrapper">
          <img src={logo} className="logo-nav-movile" alt="logo" />
          <Hamburger
            rounded
            toggled={isOpen}
            toggle={setOpen}
            onToggle={isToggled}
            color={skin === "-white" ? "#3212d8" : "#fff"}
          />
        </div>
        <ul className="nav hidden" id="nav">
          <li className={`nav-item${skin}`}>{title && title}</li>
          <li className={`nav-item${skin}`}>{subTitle && subTitle}</li>
        </ul>
      </header>
    </>
  );
}

export default HeaderMovileAppointment;
