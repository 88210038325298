import React from "react";
import { useContext } from "react";
import { ContactData } from "../../context/ContactData/ContactData";
import useWindowSize from "../../utils/useWindowSize";
import HeaderDesktopAppointment from "./HeaderDesktopAppointment";
import HeaderMobileAppointment from "./HeaderMovileAppointment";

function HeaderAppointment() {
  const { width } = useWindowSize();
  const breakpointWidth = 720;

  const { dataContact } = useContext(ContactData);
  let title = null;
  let subTitle = null;
  let logo = null;
  let url = null;

  if (dataContact) {
    title = dataContact.title;
    subTitle = dataContact.subtitle;
    logo = dataContact.logo;
    url = dataContact.url;
  }
  return (
    <>
      {width >= breakpointWidth ? (
        <HeaderDesktopAppointment
          title={title}
          subTitle={subTitle}
          logo={logo} 
          url = {url}
        />
      ) : (
        <HeaderMobileAppointment
          title={title}
          subTitle={subTitle}
          logo={logo}
        />
      )}
    </>
  );
}

export default HeaderAppointment;
