import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import "./Appointment.css";
import React from "react";
import { useContext } from "react";
import { langContext } from "../../context/lang/langContext";

function Appointment({ statusSidebar, dates }) {
  const { locale } = useContext(langContext);
  return (
    <FullCalendar
      locale={locale === "es-MX" ? "es" : ""}
      plugins={[dayGridPlugin, timeGridPlugin]}
      initialView="dayGridMonth"
      events={dates}
      eventClick={(e) => statusSidebar(e)}
      eventClassNames={"event-class"}
      viewClassNames={"calendar-i"}
    />
  );
}

export default Appointment;
