import React, { useContext } from "react";
import { ContactData } from "../../../context/ContactData/ContactData";
import Button from "../../button/button";
import { FormattedMessage } from "react-intl";
import useRequests from "../../../Hooks/useRequests";
import { useParams } from "react-router-dom";

function ChannelForm(props) {
  const { setInCall, testCamera } = props;
  const { registerActivity } = useRequests();
  const { room } = useParams();
  const { dataContact, setCounterIsOpen, usersName } = useContext(ContactData);
  const {
    room: { attendee },
  } = dataContact;
  const { skin } = useContext(ContactData);

  const leaveChannel = async () => {
    window.location.href = dataContact.room.url;
  };
  return (
    <div className="join">
      <Button
        variant={`primary--regresar${skin}`}
        onClick={() => {
          leaveChannel();
          registerActivity(attendee.name, room, "EXIT", "ROOM");
        }}
      >
        <FormattedMessage id={"FormChannel.Exit"}></FormattedMessage>
      </Button>
      <Button
        variant={`primary--regresar${skin}`}
        onClick={(e) => {
          testCamera();
          registerActivity(attendee.name, room, "CAM_TEST", "ROOM");
        }}
      >
        <FormattedMessage id={"FormChannel.TestCamera"}></FormattedMessage>
      </Button>
      {usersName.length < dataContact.room.plan.users && (
        <Button
          variant={`primary--regresar${skin}`}
          onClick={(e) => {
            e.preventDefault();
            setCounterIsOpen(true);
            setInCall(true);
            registerActivity(attendee.name, room, "JOIN", "ROOM");
          }}
        >
          <FormattedMessage id={"FormChannel.Join"}></FormattedMessage>
        </Button>
      )}
    </div>
  );
}

export default ChannelForm;
