import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ContactData } from "../../context/ContactData/ContactData";
import { langContext } from "../../context/lang/langContext";

function HeaderDesktop() {
  const { logout, isAuthenticated} = useAuth0();
  const { establecerLenguaje, locale } = useContext(langContext);
  const { skin, dataUser } = useContext(ContactData);

  return (
    <>
      <header className={`header${skin}`}>
        <div className="container-nav flex">
          <img
            src={
              skin === "-white"
                ? `${process.env.REACT_APP_CONTACT_IMG}/virtual-es-09.png`
                : `${process.env.REACT_APP_CONTACT_IMG}/logo-virtual-es.svg`
            }
            className="logo-header"
            alt="logo-virtuales"
          />
          <ul className={`nav nav${skin}`}>
            <li hidden={!isAuthenticated}>
              <FormattedMessage
                id="header.welcome"
                defaultMessage="Bienvenido(a) "
              />
              {isAuthenticated && dataUser && dataUser.name}
            </li>
            <li className="">
              <Link to="/">
                <img
                  className="imgfaq"
                  src={`${process.env.REACT_APP_CONTACT_IMG}/faq.svg`}
                  alt="faq"
                  width="55"
                  height="55"
                />
              </Link>
            </li>
            <li>
              <div>
                {locale === "es-MX" ? (
                  <img
                    className="imgfaq"
                    src={`${process.env.REACT_APP_CONTACT_IMG}/reino-unido.png`}
                    alt="faq"
                    width="40"
                    height="40"
                    onClick={() => establecerLenguaje("en-US")}
                  />
                ) : (
                  <img
                    className="imgfaq"
                    src={`${process.env.REACT_APP_CONTACT_IMG}/colombia.png`}
                    alt="faq"
                    width="40"
                    height="40"
                    onClick={() => establecerLenguaje("es-MX")}
                  />
                )}
              </div>
            </li>
            {isAuthenticated && (
              <li>
                <button
                  className="button-logout"
                  onClick={() =>
                    logout({
                      returnTo: window.location.origin
                    })
                  }
                >
                  Logout
                </button>
              </li>
            )}
          </ul>
        </div>
      </header>
    </>
  );
}

export default HeaderDesktop;
