import React from "react";
import "./style.css";
function Modal({ children, isOpen }) {
  return (
    <div className="light-box">
      <div className="popup popup-bg-white">
        <div className="btn-close" onClick={() => isOpen(false)}>
          X
        </div>
        {children}
      </div>
    </div>
  );
}

export default Modal;
