import React, { useContext } from "react";
import { ContactData } from "../../../context/ContactData/ContactData";
import Controls from "../MainScreen/Controls";

function InfoContact() {
  const { dataContact, skin, dataUser } = useContext(ContactData);
  
  return (
    <div className="flex-box">
      <div className="box-info-firt">
        <h1 className={`titulo-evento${skin}`} style={{ padding: "0" }}>
          {dataContact && dataContact.room.title}
          {dataUser && dataUser.client && dataUser.client.title}
          <br />
          <span className="weight-normal">
            {dataContact && dataContact.room.subtitle}
            {dataUser && dataUser.client && dataUser.client.subtitle}
          </span>
        </h1>
      </div>
      <a target="_blank" href={dataUser ? dataUser.client.url : dataContact && dataContact.room.url}>
        <div className={`logo-cabecera${skin}`}>
          {dataContact && <img src={dataContact.room.logo} />}
          {dataUser && dataUser.client && <img src={dataUser.client.logo} />}
        </div>
      </a>
    </div>
  );
}

export default InfoContact;
