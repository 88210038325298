import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Hamburger from "hamburger-react";
import { ContactData } from "../../context/ContactData/ContactData";


function HeaderMobile() {
  const { skin } = useContext(ContactData);
  const [isOpen, setOpen] = useState(false);

  const isToggled = (toggled) => {
    const navbar = document.getElementById("nav");
    if (toggled) {
      navbar.className = "nav";
    } else {
      navbar.className = "nav hidden";
    }
  };

  return (
    <>
      <header className={`header${skin}`}>
        <div className="wrapper">
          <img
            src={skin === "-white" ? `${process.env.REACT_APP_CONTACT_IMG}/virtual-es-09.png` :`${process.env.REACT_APP_CONTACT_IMG}/logo-virtual-es.svg`}
            className="logo-nav-movile"
            alt="logo"
          />
          <Hamburger
            rounded
            toggled={isOpen}
            toggle={setOpen}
            onToggle={isToggled}
            color={skin === "-white" ? "#3212d8" : "#fff"}
          />
        </div>
        <ul className="nav hidden" id="nav">
          <li className={`nav-item${skin}`}>
            <Link className={`nav-link${skin} bold`} to="/">
              Inicio
            </Link>
          </li>
          <li className={`nav-item${skin}`}>
            <Link className={`nav-link${skin} bold`} to="/agenda">
              Agenda
            </Link>
          </li>
          <li className={`nav-item${skin}`}>
            <Link className={`nav-link${skin} bold`} to="/">
              Preguntas frecuentes
            </Link>
          </li>
        </ul>
      </header>
    </>
  );
}

export default HeaderMobile;
