import React from "react";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ContactData } from "../../context/ContactData/ContactData";
import { langContext } from "../../context/lang/langContext";

function HeaderDesktopAppointment({ title, subTitle, logo, url }) {
  const { establecerLenguaje, locale } = useContext(langContext);
  const { skin } = useContext(ContactData);

  return (
    <>
      <header className={`header${skin}`}>
        <div className="container-nav flex">
          <a href={url}>
            <img src={logo} className="logo-header" alt="logo-virtuales" />
          </a>
          <ul className={`nav nav${skin}`}>
            <li>
              {title && title} {subTitle && subTitle}
            </li>
            <li className="">
              <Link to="/">
                <img
                  className="imgfaq"
                  src={`${process.env.REACT_APP_CONTACT_IMG}/faq.svg`}
                  alt="faq"
                  width="55"
                  height="55"
                />
              </Link>
            </li>
            <li>
              <div>
                {locale === "es-MX" ? (
                  <img
                    className="imgfaq"
                    src={`${process.env.REACT_APP_CONTACT_IMG}/reino-unido.png`}
                    alt="faq"
                    width="40"
                    height="40"
                    onClick={() => establecerLenguaje("en-US")}
                  />
                ) : (
                  <img
                    className="imgfaq"
                    src={`${process.env.REACT_APP_CONTACT_IMG}/colombia.png`}
                    alt="faq"
                    width="40"
                    height="40"
                    onClick={() => establecerLenguaje("es-MX")}
                  />
                )}
              </div>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
}

export default HeaderDesktopAppointment;
