import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { ContactData } from "../../context/ContactData/ContactData";
import "./Footer.css";

function Footer() {
  const { skin } = useContext(ContactData);
  return (
    <div className={`footer${skin}`}>
      <div className={`container flex-footer${skin}-2`}>
        <div className={`flex-footer${skin}`}>
          <a
            href="https://www.virtuales.io"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={
                skin === "-white"
                  ? `${process.env.REACT_APP_CONTACT_IMG}/virtual-es-09.png`
                  : `${process.env.REACT_APP_CONTACT_IMG}/logo-virtual-es.svg`
              }
              className="logo-footer"
              alt="logo"
            />
          </a>
          <div>
            <p className={`formtextfooter${skin}`}>
              <FormattedMessage id="Copyright"></FormattedMessage>
              <a
                style={{ color: "#46b2ce" }}
                href="https://www.virtuales.io/privacy.html"
                target="_blank"
                className="subtilte"
                rel="noopener noreferrer"
              >
                {" "}
                <FormattedMessage id="Politicas" />{" "}
              </a>
            </p>
          </div>
        </div>
        <div>{`${process.env.REACT_APP_VERSION}`}</div>
      </div>
    </div>
  );
}

export default Footer;
