import React, { createContext, useState } from "react";

export const ContactData = createContext({});

function ContactDataProvider({ children }) {
  const [dataContact, setDataContact] = useState(false);
  const [videIsMuted, setVideIsMuted] = useState(false);
  const [skin, setSkin] = useState("-default");
  const [socketUser, setSocketUser] = useState();
  const [usersName, setUsersName] = useState([]);
  const [dataUser, setDataUser] = useState(false);
  const [statusRequest, setStatusRequest] = useState(false);
  const [counterIsOpen, setCounterIsOpen] = useState(false);
  const [countDownAccess, setCountDownAccess] = useState(true);
  const [actionsUsers, setActionsUsers] = useState();

  return (
    <ContactData.Provider
      value={{
        dataContact,
        setDataContact,
        videIsMuted,
        setVideIsMuted,
        skin,
        setSkin,
        socketUser,
        setSocketUser,
        usersName,
        setUsersName,
        dataUser,
        setDataUser,
        statusRequest,
        setStatusRequest,
        counterIsOpen,
        setCounterIsOpen,
        countDownAccess,
        setCountDownAccess,
        actionsUsers,
        setActionsUsers,
      }}
    >
      {children}
    </ContactData.Provider>
  );
}

export default ContactDataProvider;
