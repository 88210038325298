import React, { useContext } from "react";
import { ContactData } from "../../context/ContactData/ContactData";
import useWindowSize from "../../utils/useWindowSize";
import HeaderDesktopLink from "./HeaderDesktopLink";
import HeaderMobile from "./headerMobile";

function HeaderLink({ userName }) {
  const { width } = useWindowSize();
  const breakpointWidth = 768;

  return (
    <>
      {width >= breakpointWidth ? (
        <HeaderDesktopLink userName={userName} />
      ) : (
        <HeaderMobile />
      )}
    </>
  );
}

export default HeaderLink;
