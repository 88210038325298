import React, { useContext, useEffect, useRef, useState } from "react";

import Loading from "../Loading/Loading";
import CameraDistribution from "../main/CameraDistribution/CameraDistribution";
import "./ChatComponent.css";
import CountDown from "./../CountDown/CountDown";
import { FormattedMessage, useIntl } from "react-intl";
import { consoleLogFunction } from "../../Funtions/functionLog";
import { ContactData } from "../../context/ContactData/ContactData";
import { useParams } from "react-router-dom";
import { createChannel, createClient } from "agora-rtm-react";
import Modal from "../Modal";
import Button from "../button/button";
import TimeControl from "../CountDown/TimeControl";
import useRequests from "../../Hooks/useRequests";

const useClient = createClient("e20fe2fdad9c40f98db4f2f99206a40a");

function ChatComponent() {
  const { room } = useParams();
  const { registerActivity } = useRequests();
  const intl = useIntl();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [remoteTimeControl, setRemoteTimeControl] = useState({
    minutes: 0,
    seconds: 0,
  });
  const [timecontrol, setTimecontrol] = useState({ minutes: 0, seconds: 0 });
  const [selected, setSelected] = useState("messages");
  const [textBtn, setTextBtn] = useState(false);
  const {
    dataContact,
    skin,
    setSocketUser,
    setUsersName,
    counterIsOpen,
    actionsUsers,
    setActionsUsers,
  } = useContext(ContactData);

  const {
    room: { streaming, attendee, time_limit, url_guest, rol, url },
  } = dataContact;

  const client = useClient();
  const file = useRef(null);
  const usersList = useRef(null);
  const [messagesReceived, setMessagesReceived] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [messageSaved, setMessageSaved] = useState("");
  const [files, setFiles] = useState([]);
  const [controlWindow, setControlWindow] = useState(false);
  const [controlSize, setControSize] = useState(false);
  const userName = attendee.name;
  const messageScroll = useRef();
  const fileScroll = useRef();
  const [ch, setCh] = useState();
  const dateUser = new Date().toLocaleTimeString("en-US");

  const [message, setMessage] = useState({
    command: "CHAT",
    name: "",
    room: streaming.channel,
    message: "",
    date: "",
    id: room,
    product: "CONTACT",
    product_id: room,
  });

  let login = async () => {
    const uid = `${streaming.uid}-${btoa(userName)}-${dateUser}-CHAT`;
    const channel = createChannel(streaming.channel_chat);
    const testChannel = channel(client);
    setCh(testChannel);
    await client.login({
      uid,
    });
    await testChannel.join();

    client.on("ConnectionStateChanged", async (state) => {
      consoleLogFunction("ConnectionStateChanged", state);
    });

    testChannel.on("ChannelMessage", (msg) => {
      const received = JSON.parse(msg.text);

      if (received.command && received.command === "FILE") {
        setFiles((prevFiles) => [...prevFiles, received]);
      } else if (received.command && received.command === "CHAT") {
        setMessagesReceived((prevMessage) => [...prevMessage, received]);
      } else if (received.command === "END-SESSION") {
        window.location = url;
      } else if (received.command === "REMOTE-CONTROL-TIME") {
        setRemoteTimeControl(received.value);
      } else {
        setSocketUser(received);
      }
    });

    testChannel.on("MemberLeft", () => {
      getMembers(testChannel);
      var attendeeIO = {
        command: "STATUS-USER",
        name: attendee.name,
        room: streaming.channel,
      };
      setSocketUser(attendeeIO);
    });

    testChannel.on("MemberJoined", () => {
      getMembers(testChannel);
      var attendeeIO = {
        command: "STATUS-USER",
        name: attendee.name,
        room: streaming.channel,
      };
      setSocketUser(attendeeIO);
    });

    getMembers(testChannel);
  };

  const getMembers = async (testChannel) => {
    const members = await testChannel.getMembers();
    const result = [];
    members.map((member) => {
      if (member !== "recording") {
        const user = member.split("-");
        if (!result.find((us) => us.name === atob(user[1]))) {
          result.push({
            name: atob(user[1]),
            date: user[2],
            uid: parseInt(user[0]),
          });
        }
      }
    });
    setOnlineUsers([...result]);
    setUsersName([...result]);
  };

  const sendMsg = async (text) => {
    let message = client.createMessage({
      text: JSON.stringify(text),
      messageType: "TEXT",
    });
    await ch.sendMessage(message).then(() => {
      if (text.command === "CHAT")
        setMessagesReceived((prevMessage) => [...prevMessage, text]);
    });
  };

  useEffect(() => {
    login();
    //window.history.pushState("nextState", "nextTitle", "/room");
  }, []);

  useEffect(() => {
    if (actionsUsers) sendMsg(actionsUsers);
    if (actionsUsers && actionsUsers.command === "RECORDING") {
      setSocketUser(actionsUsers);
    }
  }, [actionsUsers]);

  useEffect(() => {
    if (messagesReceived.length > 0) {
      messageScroll.current?.scrollIntoView({ block: "end" });
    }
    if (files.length > 0) fileScroll.current?.scrollIntoView({ block: "end" });
  }, [messagesReceived, files]);

  const handleChange = (e) => {
    e.preventDefault();
    setMessage({
      ...message,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeFile = () => {
    let dataFile = {
      command: "FILE",
      room: streaming.channel,
      name: file.current.files[0].name,
      buffer: file.current.files[0],
      type: file.current.files[0].type,
      from: attendee.name,
      id: room,
    };
    if (
      file.current.files[0].size / 1000 > 0 &&
      file.current.files[0].size / 1000 < 1000
    ) {
      setControlWindow(true);
      sendMsg(dataFile).then(() => {
        setFiles((prevFiles) => [...prevFiles, dataFile]);
        const msgReceibed = {
          command: "CHAT",
          name: attendee.name,
          room: streaming.channel,
          message: "Agrego el archivo - " + dataFile.name,
          id: room,
          date: dateUser,
        };
        sendMsg(msgReceibed);
      });
    } else {
      setControSize(true);
    }

    setTimeout(() => {
      setControlWindow(false);
      setControSize(false);
    }, 3000);
  };

  function handleKeyDown(e) {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setMessage({
        ...message,
        message: message.message + "\n",
      });
    }
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      registerActivity(userName, room, "CHAT_SEND", "ROOM");
      handleSubmit();
    }
  }

  const handleSubmit = () => {
    const date = new Date();
    if (attendee) message.name = attendee.name;
    message.message =
      message.message !== ""
        ? message.message.replace(/\r?\n/g, "<br>")
        : messageSaved.replace(/\r?\n/g, "<br>");
    message.date = date.toLocaleTimeString("en-US");
    message.room = streaming.channel;
    sendMsg(message);
    setMessageSaved(message.message);
    setMessage({
      ...message,
      message: "",
    });
  };

  const handleDecode = async (index) => {
    var newBlob = new Blob([new Uint8Array(files[index].buffer)], {
      type: files[index].type,
    });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    var data = window.URL.createObjectURL(newBlob);
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = data;
    link.download = files[index].name;
    link.click();
    window.URL.revokeObjectURL(files[index].base64String);
    link.remove();
  };

  function copyToClipboard() {
    var aux = document.createElement("input");
    aux.setAttribute("value", url_guest);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    setTextBtn(true);
    setTimeout(() => {
      setTextBtn(false);
    }, 4000);
  }

  return (
    <div className="chat" style={{ marginTop: "-10px" }}>
      {(remoteTimeControl.minutes > 0 || remoteTimeControl.seconds > 0) && (
        <TimeControl
          controlTime={remoteTimeControl}
          setRemoteTimeControl={setRemoteTimeControl}
        />
      )}
      {(timecontrol.minutes > 0 || timecontrol.seconds > 0) && !modalIsOpen && (
        <TimeControl
          controlTime={timecontrol}
          setRemoteTimeControl={setTimecontrol}
        />
      )}
      <ul className={`chat-list list${skin}`}>
        <li
          className={selected === "messages" ? `active` : `disabled${skin}`}
          onClick={() => {
            setSelected("messages");
            registerActivity(userName, room, "CHAT", "ROOM");
          }}
        >
          Chat
        </li>
        <li>|</li>
        <li
          className={selected === "assistants" ? `active` : `disabled${skin}`}
          onClick={() => {
            setSelected("assistants");
            registerActivity(userName, room, "ATTENDEES", "ROOM");
          }}
        >
          <FormattedMessage id="chat.pestañaAsistans"></FormattedMessage>
        </li>
        {true === false && <li>|</li>}
        {true === false && (
          <li
            className={selected === "files" ? `active` : `disabled${skin}`}
            onClick={() => setSelected("files")}
          >
            <FormattedMessage id="chat.pestañaFiles"></FormattedMessage>
          </li>
        )}
      </ul>
      <div className={`messages messages${skin}`}>
        {selected === "messages" && (
          <div>
            {!messagesReceived ? (
              <Loading />
            ) : (
              <div className={`messages-container${skin}`}>
                {messagesReceived.map((message, index) => {
                  try {
                    const messageLong = message.message.split("<br>");
                    return (
                      <div
                        key={index}
                        style={{ margin: "5px" }}
                        ref={messageScroll}
                      >
                        <p
                          style={{ marginBottom: "-20px" }}
                          className={`message-person${skin}`}
                        >
                          {message.name}
                          <span style={{ fontSize: "11px" }}>
                            {" " + message.date}
                          </span>
                        </p>

                        <p>
                          {messageLong &&
                            messageLong.map((element, i) => (
                              <span style={{ display: "block" }} key={i}>
                                {element}
                              </span>
                            ))}
                        </p>
                      </div>
                    );
                  } catch (error) {
                    console.error(error);
                  }
                })}
              </div>
            )}
            <form>
              <textarea
                name="message"
                placeholder={intl.formatMessage({ id: "chat.writeMessage" })}
                onChange={(e) => handleChange(e)}
                onKeyDown={handleKeyDown}
                value={message.message}
              />
            </form>
          </div>
        )}
        {selected === "assistants" && (
          <div className={`messages-container${skin}`} ref={usersList}>
            {onlineUsers &&
              onlineUsers.map((user, i) => (
                <div key={i} className="conexion">
                  <div
                    className={
                      user.status === "CHAT"
                        ? "circle-conexion-red"
                        : "circle-conexion-green"
                    }
                  ></div>
                  <div>
                    {user.name}
                    <span style={{ fontSize: "11px" }}>
                      {" " + user.date}
                      {user.status === "CHAT" && " (Pendiente)"}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        )}
        {selected === "files" && (
          <div>
            <div className={`messages-container${skin}`}>
              {controlWindow && (
                <div className="box-light">
                  <div>
                    <Loading></Loading>
                  </div>
                </div>
              )}
              {controlSize && (
                <div className="box-light">
                  <div>
                    <p style={{ margin: "0" }}>
                      El tamaño del archivo es mayor a 1MB, tamaño maximo de
                      carga es de 1MB.
                    </p>
                  </div>
                </div>
              )}
              <div>
                {files.map((message, index) => {
                  return (
                    <div key={index} style={{ margin: "5px" }} ref={fileScroll}>
                      <p
                        onClick={() => handleDecode(index)}
                        style={{ cursor: "pointer" }}
                        className={`message-person${skin}`}
                      >
                        {message.from} - {message.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>

            <form>
              <input
                type="file"
                ref={file}
                className={`input-file${skin}`}
                onChange={() => handleChangeFile()}
              ></input>
            </form>
          </div>
        )}
      </div>
      {modalIsOpen && (
        <Modal isOpen={setModalIsOpen}>
          <div className="content-time">
            <h1>
              <FormattedMessage id={"count-down.MeetTime"} />
            </h1>
            <div className="select-time">
              <p>
                <FormattedMessage id={"count-down.minutes"} />
              </p>
              <input
                type="number"
                min={0}
                step={5}
                max={time_limit}
                onChange={(e) =>
                  setTimecontrol({
                    ...timecontrol,
                    minutes: parseInt(e.target.value),
                  })
                }
              />
            </div>
            <div className="select-time">
              <p>
                <FormattedMessage id={"count-down.seconds"} />
              </p>
              <select
                onChange={(e) => {
                  setTimecontrol({
                    ...timecontrol,
                    seconds: parseInt(e.target.value),
                  });
                }}
              >
                <option>0</option>
                <option>15</option>
                <option>30</option>
                <option>45</option>
              </select>
            </div>
            <div className="select-time">
              <Button
                onClick={() => {
                  setModalIsOpen(false);
                  setActionsUsers({
                    command: "REMOTE-CONTROL-TIME",
                    name: attendee.name,
                    value: {
                      minutes: timecontrol.minutes,
                      seconds: timecontrol.seconds,
                    },
                  });
                }}
              >
                <FormattedMessage id={"count-down.save"} />
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <CameraDistribution />

      {rol === "MANAGER" && (
        <button
          onClick={() => setModalIsOpen(true)}
          className="button--add btn-copy"
        >
          <img
            width="20"
            alt="imagen Reloj"
            style={{ marginRight: "10px", color: "white !important" }}
            src={`${process.env.REACT_APP_CONTACT_IMG}/clock-on.svg`}
          ></img>
          <FormattedMessage id="chat.ControlTime" />
        </button>
      )}
      {rol === "MANAGER" && (
        <button onClick={copyToClipboard} className="button--add btn-copy">
          {textBtn
            ? intl.formatMessage({ id: "btn.copied" })
            : intl.formatMessage({ id: "btn.copyLink" })}
        </button>
      )}
      {counterIsOpen && time_limit && time_limit !== "" && (
        <CountDown nameAttendee={attendee.name} rol={rol}></CountDown>
      )}
    </div>
  );
}

export default ChatComponent;
