import React, { useRef } from "react";
import "./CountDown.css";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ContactData } from "../../context/ContactData/ContactData";

import { FormattedMessage } from "react-intl";
import Loading from "../Loading/Loading";
import { useParams } from "react-router-dom";
import { getActualHour } from "../../views/auditorium";
import useRequests from "../../Hooks/useRequests";

function CountDown({ nameAttendee, rol }) {
  const { room } = useParams();
  const { registerActivity } = useRequests();
  const { dataContact, skin, setActionsUsers, socketUser } =
    useContext(ContactData);
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  const [statusClock, setStatusClock] = useState(true);
  const [date, setDate] = useState(false);
  const interval = useRef();
  
  const addMin = () => {
    clearInterval(interval.current);
    registerActivity(nameAttendee, room, "ADD_MIN", "ROOM");
    const date2 = new Date(date);
    date2.setMinutes(date2.getMinutes() + 5);
    setDate(date2);
    var attendeeIO = {
      command: "CONTROL-TIME",
      room: dataContact.room.streaming.channel,
      id_user: dataContact.room.streaming.uid,
    };
    setActionsUsers(attendeeIO);
  };

  const addMinAttendee = () => {
    clearInterval(interval.current);
    const date2 = new Date(date);
    date2.setMinutes(date2.getMinutes() + 5);
    setDate(date2);
  };

  const startTimer = () => {
    const countDownDate = new Date(date).getTime();
    const dateString = getActualHour().toString();
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6) - 1;
    const day = dateString.slice(6, 8);
    const hour = dateString.slice(8, 10);
    const minute = dateString.slice(10, 12);
    const second = dateString.slice(12, 14);
    let now = new Date(year, month, day, hour, minute, second).getTime();

    interval.current = setInterval(() => {
      const distance = countDownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        setStatusClock(false);
        var attendeeIO = {
          command: "END",
          type: "end-meet",
          room: dataContact.room.streaming.channel,
          id_user: dataContact.room.streaming.uid,
        };
        if (dataContact.room.rol === "MANAGER") setActionsUsers(attendeeIO);
        window.location.href = dataContact.room.url;
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
      now += 1000;
    }, 1000);
  };

  useEffect(() => {
    if (dataContact) {
      const dateString = dataContact.room.date_end_utc.toString();
      const year = dateString.slice(0, 4);
      const month = dateString.slice(4, 6) - 1;
      const day = dateString.slice(6, 8);
      const hour = dateString.slice(8, 10);
      const minute = dateString.slice(10, 12);
      const second = dateString.slice(12, 14);
      const dateEnd = new Date(year, month, day, hour, minute, second);
      setDate(dateEnd);
      return () => {
        setDate(false);
      };
    }
  }, [dataContact]);

  useEffect(() => {
    if (date) {
      clearInterval(interval.current);
      startTimer();
    }
    return () => {
      clearInterval(interval.current);
    };
  }, [date]);

  useEffect(() => {
    clearInterval(interval.current);
    return () => {
      clearInterval(interval.current);
    };
  }, [statusClock]);

  useEffect(() => {
    if (socketUser && socketUser !== false) {
      if (socketUser.command === "CONTROL-TIME") {
        addMinAttendee();
      }
    }
  }, [socketUser]);

  try {
    return dataContact && date && timerMinutes !== "00" ? (
      <div>
        <h1 className={`title-color${skin}`}>
          {dataContact && (timerHours > 0 || timerMinutes > 20) && (
            <span className="text-time">
              <FormattedMessage id={"count-down.MeetTime"}></FormattedMessage>
              {timerMinutes}{" "}
              <FormattedMessage id={"count-down.minutes"}></FormattedMessage>
            </span>
          )}
          {dataContact && timerHours < 1 && timerMinutes < 21 && (
            <FormattedMessage id={"count-down.TimeLeft-t"}></FormattedMessage>
          )}
        </h1>
        {timerHours < 1 && timerMinutes < 21 && (
          <div
            className={`box-countdown${skin}-${
              rol === "MANAGER" && timerMinutes < 5 ? "MANAGER" : "ATTENDEE"
            }`}
          >
            <div className={`Countdown-item${skin}`}>
              <h1>{timerMinutes}</h1>
              <h3>
                <FormattedMessage id={"count-down.minutes"}></FormattedMessage>
              </h3>
            </div>
            <div className={`Countdown-item${skin}`}>
              <h1>:</h1>
            </div>
            <div className={`Countdown-item${skin}`}>
              <h1>{timerSeconds}</h1>
              <h3>
                <FormattedMessage id={"count-down.seconds"}></FormattedMessage>
              </h3>
            </div>
            {rol === "MANAGER" && timerMinutes < 5 && (
              <div className={`Countdown-item${skin}`}>
                <div className={`content-btn-add-min${skin}`}>
                  <button className="btn-add-min" onClick={addMin}>
                    <FormattedMessage id={"count-down.add-time"} />
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    ) : (
      <Loading></Loading>
    );
  } catch (error) {
    console.error(error);
  }
}

export default CountDown;
