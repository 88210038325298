import React, { useState } from "react";
import VideoCall from "./VideoCall";
import ChannelForm from "./ChannelForm";
import Button from "../../button/button";
import { FormattedMessage } from "react-intl";
import { useContext } from "react";
import { ContactData } from "../../../context/ContactData/ContactData";
import AgoraRTC from "agora-rtc-sdk-ng";
import { AgoraVideoPlayer } from "agora-rtc-react";
import Loading from "../../Loading/Loading";
import { useParams } from "react-router-dom";

function VideoInfo({ children }) {
  const [inCall, setInCall] = useState(false);
  const [testCameraIsOpen, setTestCameraIsOpen] = useState(false);
  const [tracks, setTracks] = useState(false);
  const { room } = useParams();
  const { skin } = useContext(ContactData);

  const testCamera = () => {
    setTestCameraIsOpen(true);

    AgoraRTC.getDevices().then(async (devices) => {
      const audioDevices = devices.filter(function (device) {
        return device.kind === "audioinput";
      });
      const videoDevices = devices.filter(function (device) {
        return device.kind === "videoinput";
      });

      var selectedMicrophoneId = audioDevices[0].deviceId;
      var selectedCameraId = videoDevices[0].deviceId;
      const getDevices = [
        await AgoraRTC.createCameraVideoTrack({ cameraId: selectedCameraId }),
        await AgoraRTC.createMicrophoneAudioTrack({
          microphoneId: selectedMicrophoneId,
        }),
      ];
      setTracks(getDevices);
    });
  };

  return (
    <section className="videoMain">
      <div>
        <div>
          {inCall ? (
            <div className={"display-video"}>
              <VideoCall setInCall={setInCall} channelName={room} />
            </div>
          ) : (
            <div className="display-video">
              {testCameraIsOpen && (
                <div className="test-camera">
                  <div className="test-camera-popup">
                    <div className="display-test">
                      {tracks ? (
                        <AgoraVideoPlayer
                          className="video-test"
                          videoTrack={tracks[0]}
                        />
                      ) : (
                        <Loading></Loading>
                      )}
                    </div>
                    <div className="test-camera-buttons">
                      <Button
                        variant={`primary--regresar${skin}`}
                        onClick={() => {
                          setTestCameraIsOpen(false);
                          tracks[0].setEnabled(false);
                          tracks[1].setEnabled(false);
                        }}
                      >
                        <FormattedMessage
                          id={"FormChannel.Exit"}
                        ></FormattedMessage>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              <img
                src={`${process.env.REACT_APP_CONTACT_IMG}/fondo.jpeg`}
                alt="main screen"
                className="not-video"
              />
              <ChannelForm setInCall={setInCall} testCamera={testCamera} />
            </div>
          )}
        </div>

        {children}
      </div>
    </section>
  );
}

export default VideoInfo;
