import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import AgoraRTC from "agora-rtc-sdk-ng";
import { AgoraVideoPlayer } from "agora-rtc-react";
import { ContactData } from "../../context/ContactData/ContactData";
import Button from "../button/button";
import Loading from "../Loading/Loading";
import "./CountDown.css";

function CountDownControlAccess() {
  const { dataContact, skin, setCountDownAccess } = useContext(ContactData);
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  const [statusClock, setStatusClock] = useState(true);
  const [date, setDate] = useState();
  const [distanceGlobal, setDistanceGlobal] = useState(0);
  const [testCameraIsOpen, setTestCameraIsOpen] = useState(false);
  const [tracks, setTracks] = useState(false);
  let interval = useRef();

  const startTimer = () => {
    const countDownDate = new Date(date).getTime();
    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      setDistanceGlobal(distance);
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        setStatusClock(false);
        setCountDownAccess(false);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  const testCamera = () => {
    setTestCameraIsOpen(true);

    AgoraRTC.getDevices().then(async (devices) => {
      const audioDevices = devices.filter(function (device) {
        return device.kind === "audioinput";
      });
      const videoDevices = devices.filter(function (device) {
        return device.kind === "videoinput";
      });

      var selectedMicrophoneId = audioDevices[0].deviceId;
      var selectedCameraId = videoDevices[0].deviceId;
      const getDevices = [
        await AgoraRTC.createCameraVideoTrack({ cameraId: selectedCameraId }),
        await AgoraRTC.createMicrophoneAudioTrack({
          microphoneId: selectedMicrophoneId,
        }),
      ];
      setTracks(getDevices);
    });
  };

  useEffect(() => {
    if (dataContact) {
      const remain = new Date(dataContact.room.date_schedule);
      setDate(remain);
      return () => {
        setDate(false);
      };
    }
  }, [dataContact]);

  useEffect(() => {
    if (date) startTimer();
    return () => {
      clearInterval(interval.current);
    };
  }, [date]);

  useEffect(() => {
    clearInterval(interval.current);
    return () => {};
  }, [statusClock]);

  return distanceGlobal > 0 ? (
    <div>
      <h1 className={`title-color${skin}`}>
        <FormattedMessage id={"count-down.TimeLeft"}></FormattedMessage>
      </h1>
      <div className={`box-countdown-access${skin}`}>
        <div className={`Countdown-item${skin}`}>
          <h1>{timerDays}</h1>
          <h3>
            <FormattedMessage id={"count-down.Days"}></FormattedMessage>
          </h3>
        </div>
        <div className={`Countdown-item${skin}`}>
          <h1>:</h1>
        </div>
        <div className={`Countdown-item${skin}`}>
          <h1>{timerHours}</h1>
          <h3>
            <FormattedMessage id={"count-down.hours"}></FormattedMessage>
          </h3>
        </div>
        <div className={`Countdown-item${skin}`}>
          <h1>:</h1>
        </div>
        <div className={`Countdown-item${skin}`}>
          <h1>{timerMinutes}</h1>
          <h3>
            <FormattedMessage id={"count-down.minutes"}></FormattedMessage>
          </h3>
        </div>
        <div className={`Countdown-item${skin}`}>
          <h1>:</h1>
        </div>
        <div className={`Countdown-item${skin}`}>
          <h1>{timerSeconds}</h1>
          <h3>
            <FormattedMessage id={"count-down.seconds"}></FormattedMessage>
          </h3>
        </div>
      </div>
      <div className="box-camera">
        {testCameraIsOpen && (
          <div className={`box-test-camera${skin}`}>
            <div className="display-test-camera">
              {tracks ? (
                <AgoraVideoPlayer
                  className="video-test"
                  videoTrack={tracks[0]}
                />
              ) : (
                <Loading></Loading>
              )}
            </div>
            <Button
              variant={"primary"}
              onClick={() => {
                setTestCameraIsOpen(false);
                tracks[0].setEnabled(false);
                tracks[1].setEnabled(false);
              }}
            >
              <FormattedMessage id={"popup.btnExit"}></FormattedMessage>
            </Button>
          </div>
        )}
        {!testCameraIsOpen && (
          <Button
            variant={"primary"}
            onClick={() => {
              testCamera();
              setTestCameraIsOpen(true);
            }}
          >
            <FormattedMessage id={"FormChannel.TestCamera"}></FormattedMessage>
          </Button>
        )}
        <div style={{width: "25px"}}></div>
        {!testCameraIsOpen && (
          <Button
            variant={"primary"}
            onClick={() => {
              window.location.href = `${dataContact.room.url}`
            }}
          >
            <FormattedMessage id={"FormChannel.Exit"}></FormattedMessage>
          </Button>
        )}
      </div>
    </div>
  ) : (
    <Loading></Loading>
  );
}

export default CountDownControlAccess;
