import "./ContactDetail.css";

import { useAuth0 } from "@auth0/auth0-react";
import Button from "../button/button";

import { FormattedMessage } from "react-intl";
import InfoContactHome from "../main/BannerLogo/InfoContactHome";
import React, { useEffect } from "react";

function ContactDetail() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (window.location.pathname === "/schedule") window.location = "";
  }, [window.location.pathname]);

  const ingresar = () => {
    window.location = `/schedule`;
  };

  const login = () => {
    loginWithRedirect({
      appState: {
        returnTo: `schedule`,
      },
    });
  };

  return (
    <main className="container main-bg">
      <>
        <InfoContactHome data={"data2"} />
        <div className="grid margin25">
          <div>
            <picture className="picture">
              <img
                src={`${process.env.REACT_APP_CONTACT_IMG}/gestion-entrada.jpg`}
                className="picture"
              />
            </picture>
          </div>
          <div className="box">
           
          </div>
        </div>
      </>
    </main>
  );
}

export default ContactDetail;
