import VirtualBackgroundExtension from "agora-extension-virtual-background";
import AgoraRTC, { createClient } from "agora-rtc-react";
import React, { useContext, useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { usersConected } from "../../../context/CamsConected/CamsConected";
import { ContactData } from "../../../context/ContactData/ContactData";
import { consoleLogFunction } from "../../../Funtions/functionLog";
import VideoRecording from "./VideoRecording";

const config = {
  mode: "rtc",
  codec: "vp8",
};

const useClient = createClient(config);
const useDesktopClient = createClient(config);
const extension = new VirtualBackgroundExtension();
AgoraRTC.registerExtensions([extension]);

let namesUsers = [];

function VideoCallRecording() {
  const {
    screens,
    setScreens,
    localScreen,
    setSpeakers,
    speakers,
    usersDisconectedImage,
  } = useContext(usersConected);
  const { dataContact, usersName } = useContext(ContactData);
  const {
    room: { streaming },
  } = dataContact;
  const [loginUser, setLoginUser] = useState(false);
  const [loginScreen, setLoginScreen] = useState(false);

  const client = useClient();
  let screenClient = useDesktopClient();
  const handleFullScreen = useFullScreenHandle();

  const loginScreenUser = () => {
    consoleLogFunction(
      "videoCall:306 -- conectado --",
      "se conecto compartir pantalla"
    );
    if (!loginScreen)
      screenClient
        .join(
          streaming.app,
          streaming.channel_desktop,
          streaming.token_desktop,
          streaming.uid_desktop
        )
        .then(() => {
          setLoginScreen(true);
          consoleLogFunction("videocall:206", "::conectado::");
        })
        .catch(() => {
          setLoginScreen(false);
          consoleLogFunction("videoCall:210", "::desconectado::");
        });

    screenClient.on("user-published", async (user, mediaType) => {
      await screenClient.subscribe(user, mediaType);
      if (mediaType === "video" && user.videoTrack !== undefined) {
        setScreens((prevUsers) => {
          return [...prevUsers, user];
        });
      }
      if (mediaType === "audio" && user.audioTrack !== undefined) {
        user.audioTrack.play();
      }
    });

    screenClient.on("user-left", (user) => {
      setScreens((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid);
      });
    });

    screenClient.on("user-unpublished", (user, type) => {
      if (type === "video" && user.videoTrack !== undefined) {
        setScreens((prevUsers) => {
          user.videoTrack.stop();
          consoleLogFunction(
            "videoCall:235 -- user-unpublished-screenClient",
            user.videoTrack
          );
          return prevUsers.filter((User) => User.uid !== this.user.uid);
        });
      }
    });
  };

  const getUserName = async (uid) => {
    let name;
    namesUsers.map((user) =>
      user.uid === uid ? (name = user.name) : "undefined"
    );
    return name;
  };

  useEffect(() => {
    namesUsers = [...usersName];
    return () => {};
  }, [usersName]);

  useEffect(() => {
    let init = async () => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);

        setTimeout(async () => {
          const userName = await getUserName(user.uid);
          user.userName = userName;

          setSpeakers((prevUsers) => {
            if (!prevUsers.find((usr) => usr.uid === user.uid)) {
              return [...prevUsers, user];
            } else {
              const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
              return [...usrs, user];
            }
          });

          if (mediaType === "audio" && user.audioTrack !== undefined) {
            user.audioTrack.play();
          }
        }, 2000);
      });

      client.on("user-unpublished", (user, type) => {
        if (type === "audio" && user.audioTrack !== undefined) {
          user.audioTrack.stop();
        }
        setSpeakers((prevUsers) => {
          if (!prevUsers.find((usr) => usr.uid === user.uid)) {
            return [...prevUsers, user];
          } else {
            const usrs = prevUsers.filter((usr) => usr.uid !== user.uid);
            return [...usrs, user];
          }
        });
      });

      client.on("user-left", (user) => {
        setSpeakers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      if (streaming && !loginUser)
        await client
          .join(
            streaming.app,
            streaming.channel,
            streaming.token,
            streaming.uid
          )
          .then(() => {
            setLoginUser(true);
            consoleLogFunction("videoCall:390", "::conectado::");
          })
          .catch(() => {
            setLoginUser(false);
            consoleLogFunction("videoCall:394", "::desconectado::");
          });

      //setStart(true);
    };

    init();
    loginScreenUser();
    return () => {};
  }, []);

  return (
    <div className="vid-recording">
      <FullScreen handle={handleFullScreen}>
        <div>
          <VideoRecording
            users={speakers}
            localScreen={localScreen}
            screens={screens}
            fullScreen={handleFullScreen}
            usersDisconectedImage={usersDisconectedImage}
          />
        </div>
      </FullScreen>
    </div>
  );
}

export default VideoCallRecording;
