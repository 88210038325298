import { AgoraVideoPlayer } from "agora-rtc-react";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ContactData } from "../../../context/ContactData/ContactData";
import Button from "../../button/button";

import Controls from "./Controls";
import ControlsStatesUsers from "./ControlsStatesUsers";
import ControlsUsers from "./ControlsUsers";
import "./Videos.css";

function Videos(props) {
  const {
    users,
    localScreen,
    tracks,
    screens,
    fullScreen,
    usersDisconectedImage,
    dataControls,
  } = props;
  const { dataContact, videIsMuted, socketUser, skin } =
    useContext(ContactData);

  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [userName, setUserName] = useState();
  const [typeTrack, setTypeTrack] = useState();
  const {
    room: { attendee, rol, streaming },
  } = dataContact;
  const trackValue = !tracks ? -1 : 0;

  useEffect(() => {
    if (socketUser && socketUser.command === "CONTROL-USER") {
      setUserName(socketUser.name);
      if (socketUser.type === "audio" && streaming.uid === socketUser.id_user) {
        setTypeTrack("audio");
        setPopupIsOpen(true);
      }
      if (socketUser.type === "video" && streaming.uid === socketUser.id_user) {
        setTypeTrack("video");
        setPopupIsOpen(true);
      }
    }
  }, [socketUser]);

  try {
    return (
      <div className="screen">
        {popupIsOpen && (
          <div className="test-camera">
            <div className="test-camera-popup">
              <h3 className="title-h3">
                El usuario {userName} intenta encender tu {typeTrack}. Dar
                permiso?
              </h3>
              <div className="popup-buttons">
                <Button
                  variant={`primary--regresar${skin}`}
                  onClick={() => {
                    if (!trackState.video && typeTrack === "video") {
                      tracks[1].setEnabled(true);
                      setTrackState((ps) => {
                        return { ...ps, video: true };
                      });
                    }
                    if (!trackState.audio && typeTrack === "audio") {
                      tracks[0].setEnabled(true);
                      setTrackState((ps) => {
                        return { ...ps, audio: true };
                      });
                    }
                    setPopupIsOpen(false);
                  }}
                >
                  <FormattedMessage
                    id={"register.btnAceppt"}
                  ></FormattedMessage>
                </Button>
                <Button
                  variant={`primary--regresar${skin}`}
                  onClick={() => {
                    setPopupIsOpen(false);
                  }}
                >
                  <FormattedMessage
                    id={"register.btnCancel"}
                  ></FormattedMessage>
                </Button>
              </div>
            </div>
          </div>
        )}
        <div
          id={fullScreen.active ? "full-screen" : "videos"}
          className={
            localScreen || screens.length > 0 || (!tracks && users.length === 1)
              ? "video-grid-auditorio-sreen"
              : `video-grid-auditorio-${
                  users.length + usersDisconectedImage.length + trackValue
                }`
          }
        >
          {screens.length > 0 && (
            <div style={{ width: "100%", height: "calc(100% - 40px)" }}>
              <AgoraVideoPlayer
                style={{ height: "calc(100%)", width: "100%" }}
                videoTrack={screens[0].videoTrack}
              />
              <div style={{ width: "100%", position: "relative" }}>
                {" "}
                <Controls
                  variant={"controls-screen"}
                  screens={screens}
                  trackState={trackState}
                  setTrackState={setTrackState}
                  dataControls={dataControls}
                />
              </div>
            </div>
          )}

          {!localScreen && screens.length === 0 && tracks && (
            <div
              style={
                users.length === 0
                  ? { width: "100%", height: "calc(100%)" }
                  : {}
              }
              className={"a-0"}
            >
              {videIsMuted ? (
                <div style={{ width: "100%", height: "calc(100%)" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "calc(100% - 40px)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_CONTACT_IMG}/fondo-sin-cam-1.jpg`}
                      className="image-distribution"
                    />
                  </div>
                  <div style={{ width: "100%", position: "relative" }}>
                    {" "}
                    <Controls
                      variant={"controls"}
                      screens={screens}
                      trackState={trackState}
                      setTrackState={setTrackState}
                      dataControls={dataControls}
                    />
                  </div>
                  <div className="name-user">{attendee.name}</div>
                </div>
              ) : (
                tracks !== false && (
                  <div style={{ width: "100%", height: "100%" }}>
                    <AgoraVideoPlayer
                      className="agora-video"
                      videoTrack={tracks[1]}
                    />
                    <div style={{ width: "100%", position: "relative" }}>
                      {" "}
                      <Controls
                        variant={"controls"}
                        screens={screens}
                        trackState={trackState}
                        setTrackState={setTrackState}
                        dataControls={dataControls}
                      />
                    </div>

                    <div className="name-user">{attendee.name}</div>
                  </div>
                )
              )}
            </div>
          )}

          {users.length > 0 &&
            !localScreen &&
            screens.length === 0 &&
            users.map((user, index) => (
              <div
                style={{ width: "100%", height: "100%" }}
                className={`a-${index + 1}`}
                key={user.uid}
              >
                {!user._video_muted_ && user.videoTrack !== undefined ? (
                  <AgoraVideoPlayer
                    className="agora-video-users"
                    videoTrack={user.videoTrack}
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "calc(100% - 40px)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_CONTACT_IMG}/fondo-sin-cam-1.jpg`}
                      className="image-distribution"
                    />
                  </div>
                )}
                <div style={{ width: "100%", position: "relative" }}>
                  {rol && rol === "MANAGER" ? (
                    <ControlsUsers
                      fullScreen={dataControls.fullScreen}
                      stateVideo={true}
                      userId={user.uid}
                      videoMuted={user._video_muted_}
                      audioMuted={user._audio_muted_}
                    ></ControlsUsers>
                  ) : (
                    <ControlsStatesUsers
                      userId={user.uid}
                      audioMuted={user._audio_muted_}
                    ></ControlsStatesUsers>
                  )}
                </div>
                <div className="name-user">{user.userName}</div>
              </div>
            ))}

          {!tracks &&
            usersDisconectedImage.length === 0 &&
            users.length === 0 &&
            !localScreen &&
            screens.length === 0 && (
              <img
                src={`${process.env.REACT_APP_CONTACT_IMG}/fondo.jpeg`}
                alt="main screen"
                style={{ width: "100%", position: "relative" }}
              />
            )}

          {localScreen && (
            <div style={{ width: "100%", height: "calc(100% - 40px)" }}>
              <AgoraVideoPlayer
                style={{ height: "100%", width: "100%" }}
                className="vid"
                videoTrack={localScreen}
              />
              <div style={{ width: "100%", position: "relative" }}>
                {" "}
                <Controls
                  variant={"controls-screen"}
                  screens={screens}
                  trackState={trackState}
                  setTrackState={setTrackState}
                  dataControls={dataControls}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } catch (error) {
    console.erro(error);
  }
}

export default Videos;
