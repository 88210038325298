import React from "react";
import "./App.css";
import ContactDataProvider from "./context/ContactData/ContactData";
import AccessDenied from "./views/accessDenied";
import Auditorium from "./views/auditorium";
import Error404 from "./views/Error404";
import ViewAppointment from "./views/ViewAppointment";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ViewRecording from "./views/ViewRecording";

function App() {
  return (
    <ContactDataProvider>
      <BrowserRouter>
        <Routes>
          <Route index path="/" element={<AccessDenied />} />
          <Route index path="/auditorio" element={<Auditorium />} />
          <Route index path="/room/:room" element={<Auditorium />} />
          <Route
            index
            path="/room/:room/:style"
            element={<Auditorium />}
          />
          <Route index path="/room" element={<AccessDenied />} />

          <Route
            index
            path="/appointment/:appointment"
            element={<ViewAppointment />}
          />
          <Route
            index
            path="/room-recording/:room"
            element={<ViewRecording />}
          />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </ContactDataProvider>
  );
}

export default App;
