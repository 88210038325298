import React, { useContext, useEffect } from "react";
import "./header.css";
import { useAuth0 } from "@auth0/auth0-react";
import HeaderMobile from "./headerMobile";
import HeaderDesktop from "./headerDesktop";
import useWindowSize from "../../utils/useWindowSize";
import { ContactData } from "../../context/ContactData/ContactData";
import useRequests from "../../Hooks/useRequests";

function Header() {
  const { width } = useWindowSize();
  const breakpointWidth = 768;
  const { isAuthenticated } = useAuth0();
  const { getWithTokenAuth0 } = useRequests();
  const { setDataUser, setStatusRequest } = useContext(ContactData);

  useEffect(() => {
    if (isAuthenticated)
      (async () => {
        const getData = await getWithTokenAuth0(
          `${process.env.REACT_APP_URL_API_MNG}/user`
        );
        setStatusRequest(getData.response);
        setDataUser(getData.result);
      })();
  }, [isAuthenticated]);

  return <>{width >= breakpointWidth ? <HeaderDesktop /> : <HeaderMobile />}</>;
}

export default Header;
