import React, { useContext, useEffect } from "react";
import "./main.css";
import InfoContact from "./BannerLogo/InfoContact";
import VideoInfoWrapper from "./mainVideoChat";
import ChatComponent from "../ChatComponent/ChatComponent";
import HandleSocket from "../../context/HandleSocket/HandleSocket";
import useRequests from "../../Hooks/useRequests";
import { ContactData } from "../../context/ContactData/ContactData";
import { useParams } from "react-router-dom";

function Main() {
  const { registerActivity } = useRequests();
  const { room } = useParams();
  const { dataContact } = useContext(ContactData);
  const {
    room: { attendee },
  } = dataContact;

  useEffect(() => {
    registerActivity(attendee.name, room, "ACCESS", "ROOM");
  }, []);

  return (
    <>
      <InfoContact />
      <HandleSocket>
        <section className="auditorium">
          <VideoInfoWrapper />
          <ChatComponent />
        </section>
      </HandleSocket>
    </>
  );
}

export default Main;
