function useRequests() {
  const tokenCognito = async () => {
    const options = {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    if (
      !isKeyExists(options.headers, "Origin") &&
      process.env.REACT_APP_LOCAL === "false"
    ) {
      options.headers.Referer = `${process.env.REACT_APP_REFERER}`;
      options.headers.Origin = `${process.env.REACT_APP_ORIGIN}`;
    }

    const response = await fetch(
      `https://${process.env.REACT_APP_COGNITO_URL}?client_id=${process.env.REACT_APP_COGNITO_CLIENT}&client_secret=${process.env.REACT_APP_COGNITO_SECRET}&grant_type=client_credentials&client_name=${process.env.REACT_APP_AUTH0_DOMAIN}`,
      options
    );
    const result = await response.json();

    return result;
  };

  const getWithToken = async (url) => {
    const accesToken = await tokenCognito();
    let headers = {
      headers: { Authorization: `Bearer ${accesToken.access_token}` },
    };

    const response = await fetch(url, headers);
    const result = await response.json();
    return { response, result };
  };

  const postWithToken = async (url, body) => {
    const accesToken = await tokenCognito();
    let options = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${accesToken.access_token}`,
        "Content-Type": "application/json",
      },
    };

    if (
      !isKeyExists(options.headers, "Origin") &&
      process.env.REACT_APP_LOCAL === "false"
    ) {
      options.headers.Referer = `${process.env.REACT_APP_REFERER}`;
    }

    const response = await fetch(url, options);
    const result = await response.json();

    return { response, result };
  };

  const registerActivity = async (
    attendee,
    room,
    action,
    screen,
    attendee_action
  ) => {
    const accesToken = await tokenCognito();
    const body = !attendee_action
      ? {
          attendee: attendee,
          screen: screen,
          action: action,
        }
      : {
          attendee: attendee,
          screen: screen,
          action: action,
          attendee_action: attendee_action,
        };

    let options = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${accesToken.access_token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${process.env.REACT_APP_URL_API}/rooms/${room}/activity`,
      options
    );
    const result = await response.json();
    return { response, result };
  };

  return {
    getWithToken,
    postWithToken,
    registerActivity,
  };
}

function isKeyExists(obj, key) {
  if (obj[key] === undefined) {
    return false;
  } else {
    return true;
  }
}

export default useRequests;
